import React from 'react';
import PropTypes from 'prop-types';

const fallbackImage =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ3IiBoZWlnaHQ9IjExMSIgdmlld0JveD0iMCAwIDE0NyAxMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQo8cGF0aCBkPSJNMTEyLjk0IDgyLjVDMTE0Ljg2OSA3OS42NjU0IDExNiA3Ni4yMTgxIDExNiA3Mi41VjQ1LjI3NzhDMTE2IDM1LjYxMjggMTA4LjM1NiAyNy43Nzc4IDk4LjkyNjUgMjcuNzc3OEg5Ni4yMzg5Qzk1LjgwMzUgMjYuNzcyNSA5NS4yMjg0IDI1Ljc5NDMgOTQuNTAzMSAyNC44NjUxQzkyLjEwMyAyMS43OTAxIDg4LjQ2OTQgMjAgODQuNjI3NCAyMEg2MS4zNzI2QzU3LjUzMDYgMjAgNTMuODk3IDIxLjc5MDEgNTEuNDk2OSAyNC44NjUxQzUwLjc3MTcgMjUuNzk0MyA1MC4xOTY1IDI2Ljc3MjUgNDkuNzYxMSAyNy43Nzc4SDQ3LjA3MzVDNDQuMDQ3NSAyNy43Nzc4IDQxLjIwNTMgMjguNTg0NyAzOC43NDA2IDMwTTEwNy41IDg3LjYzN0MxMDQuOTggODkuMTM5NSAxMDIuMDUxIDkwIDk4LjkyNjUgOTBINDcuMDczNUMzNy42NDQxIDkwIDMwIDgyLjE2NSAzMCA3Mi41VjQ1LjI3NzhDMzAgNDEuODY5OSAzMC45NTAzIDM4LjY4OTYgMzIuNTk0MiAzNiIgc3Ryb2tlPSIjODY4Njg2IiBzdHJva2Utd2lkdGg9IjMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPg0KPHBhdGggZD0iTTgwLjc1IDY5LjcyNTdDNzguMzIzMSA3MS4xNzIxIDc1LjUwNTQgNzIgNzIuNSA3MkM2My4zODczIDcyIDU2IDY0LjM4ODggNTYgNTVDNTYgNTMuNzg1NSA1Ni4xMjM2IDUyLjYwMDcgNTYuMzU4NiA1MS40NTgzTTU5LjQzNzUgNDQuNjEyNEM2Mi40NTUxIDQwLjU5MDIgNjcuMTg0IDM4IDcyLjUgMzhDODEuNjEyNyAzOCA4OSA0NS42MTEyIDg5IDU1Qzg5IDU4LjIwMyA4OC4xNDAyIDYxLjE5OTIgODYuNjQ1NSA2My43NTY4IiBzdHJva2U9IiM4Njg2ODYiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+DQo8cGF0aCBkPSJNMzAgMjVMMTE3IDg2IiBzdHJva2U9IiM4Njg2ODYiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+DQo8L3N2Zz4=';

function handleImageError(e) {
  e.target.onerror = null;
  e.target.src = fallbackImage;
  e.target.title = 'NO IMAGE';
}

const FailsafeImage = ({ src, draggable = true, alt = '' }) => {
  return (
    <img
      src={src}
      onError={handleImageError}
      alt={alt}
      draggable={draggable}
      data-testid="failsafe-image"
    />
  );
};

FailsafeImage.propTypes = {
  src: PropTypes.string.isRequired,
  draggable: PropTypes.bool,
  alt: PropTypes.string,
};

export default FailsafeImage;
