import React, { useState } from 'react';
import './ImageGrid.css';
import Spinner from '../Spinner/Spinner';
import Modal from '../Modal/Modal';
import PropTypes from 'prop-types';

const ImageGrid = ({ images, columns = 5, getLargeUrl }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState('');

  const expandImage = (image) => {
    const largeUrl = getLargeUrl(image);
    setShowModal(true);

    const img = new Image();
    img.onload = (image) => {
      setModalImageUrl(largeUrl);
    };
    img.src = largeUrl;
  };

  return (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: `repeat(${columns}, 1fr)`,
          gridGap: 10,
          padding: 10,
        }}
        data-testid="image-grid"
      >
        {images &&
          images.map((image) => (
            <img
              key={image.imageUrl}
              src={image.imageUrl}
              className="image-grid-item"
              data-testid="image-grid-item"
              onClick={() => expandImage(image)}
              alt="vehicle"
            ></img>
          ))}
      </div>
      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setModalImageUrl('');
        }}
      >
        <img
          className={`${modalImageUrl ? '' : 'no-display'}`}
          src={modalImageUrl}
          alt="full vehicle"
        />
        <div className={`image-grid-modal-spinner ${modalImageUrl ? 'no-display' : ''}`}>
          <Spinner
            height="80px"
            width="80px"
            radius="30"
          />
        </div>
      </Modal>
    </>
  );
};

ImageGrid.propTypes = {
  images: PropTypes.array,
  columns: PropTypes.number,
  getLargeUrl: PropTypes.func,
};

export default ImageGrid;
