import PropsDescription from '../../components/PropsDescription/PropsDescription';
import Question from '../../icons/Question';
import { Tooltip } from '../../reusableComponents';
import styles from './TooltipContainer.module.scss';

const TooltipContainer = () => {
  const TooltipProps = [
    {
      name: 'text',
      type: 'string',
      notes: 'Text to be displayed in the tooltip',
    },
    {
      name: 'maxwidth',
      type: 'string',
      notes: 'The max width of the tooltip',
    },
    {
      name: 'space',
      type: 'number',
      notes: 'The amount of space between the tooltip and the underlined text in pixels',
    },
    {
      name: 'position',
      type: 'string',
      notes: 'Position reference of tooltip: top | bottom | left | right',
    },
    {
      name: 'style',
      type: 'object: { tooltipContainer: string, tooltip: string }',
      notes: 'Apply additional styling to tooltip',
    },
  ];

  const text = "Hello I'm a tooltip!";
  const maxwidth = '200px';
  const space = 15;

  return (
    <div className="page-component-spacing ">
      <h4>Tooltip Component</h4>
      <div className={styles.tooltipContainer}>
        <Tooltip
          text={text}
          maxwidth={maxwidth}
          space={space}
        >
          I'm a Default Tooltip!
        </Tooltip>
      </div>
      <div className={styles.tooltipContainer}>
        <Tooltip
          text={text}
          maxwidth={maxwidth}
          space={space}
          position="top"
        >
          I'm a Top Tooltip!
        </Tooltip>
      </div>
      <div className={styles.tooltipContainer}>
        <Tooltip
          text={text}
          maxwidth={maxwidth}
          space={space}
          position="bottom"
        >
          I'm a Bottom Tooltip!
        </Tooltip>
      </div>
      <div className={styles.tooltipContainer}>
        <Tooltip
          text={text}
          maxwidth={maxwidth}
          space={space}
          position="left"
        >
          I'm a Left Tooltip!
        </Tooltip>
      </div>
      <div className={styles.tooltipContainer}>
        <Tooltip
          text={text}
          maxwidth={maxwidth}
          space={space}
          position="right"
        >
          I'm a Right Tooltip!
        </Tooltip>
      </div>
      <div className={styles.tooltipContainer}>
        <Tooltip
          text={text}
          position="top"
          style={{ tooltip: styles.tooltip }}
        >
          <Question />
        </Tooltip>
      </div>
      <div className={styles.tooltipContainer}>
        <Tooltip
          text={text}
          position="bottom"
          style={{ tooltip: styles.tooltip }}
        >
          <Question />
        </Tooltip>
      </div>
      <div className={styles.tooltipContainer}>
        <Tooltip
          text={text}
          position="left"
          style={{ tooltip: styles.tooltip }}
        >
          <Question />
        </Tooltip>
      </div>
      <div className={styles.tooltipContainer}>
        <Tooltip
          text={text}
          position="right"
          style={{ tooltip: styles.tooltip }}
        >
          <Question />
        </Tooltip>
      </div>
      <div className="page-component-spacing">
        <PropsDescription componentProps={TooltipProps} />
      </div>
    </div>
  );
};

export default TooltipContainer;
