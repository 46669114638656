import React from 'react';

export default function CameraIcon({ color = '#00528A', ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth="2"
      {...rest}
    >
      <path
        d="M17.0615 5.98064L17.1934 6.27778H17.5185H18.1481C20.0811 6.27778 21.6481 7.84478 21.6481 9.77778V16C21.6481 17.933 20.0811 19.5 18.1481 19.5H6C4.06701 19.5 2.5 17.933 2.5 16V9.77778C2.5 7.84478 4.067 6.27778 6 6.27778H6.62964H6.95474L7.08664 5.98064C7.17107 5.79043 7.28337 5.60359 7.42675 5.42436C7.89415 4.84011 8.60179 4.5 9.35 4.5H14.7981C15.5464 4.5 16.254 4.84011 16.7214 5.42436C16.8648 5.60358 16.9771 5.79043 17.0615 5.98064Z"
        stroke={color}
      />
      <circle
        cx="12"
        cy="12"
        r="3.5"
        stroke={color}
      />
    </svg>
  );
};
