import React, { useContext } from 'react';

export const NavigationContext = React.createContext({});

/**
 * Gets routing components from context.
 * (defaults to native components)
 */
export const useNavigation = () => {
  const routerContext = useContext(NavigationContext);
  return routerContext;
}

export const NavigationProvider = ({children, implementation}) => {
  return (
    <NavigationContext.Provider value={implementation}>
      {children}
    </NavigationContext.Provider>
  );
}