import React from 'react';
import SortableImageGrid from '../SortableImageGrid/SortableImageGrid';
import DragAndDrop from './DragAndDrop';
import ImageList from './ImageList';
import './MediaUpload.css';

const MediaUpload = ({
  images,
  listType = 'list',
  onUpload,
  onUploadQueueComplete,
  onDeleteImage,
  onReorderImageUp,
  onReorderImageDown,
  onReorderSave,
  showReorderSave,
  onReorder,
  getLargeUrl,
  maxPhotoLength,
  formats,
  allowMultiFile,
  maxSize,
  columns,
  loading = false,
  sendAnalytics,
}) => {
  return (
    <>
      <div className="dropzone">
        <DragAndDrop
          onUpload={onUpload}
          onUploadQueueComplete={onUploadQueueComplete}
          allowMultiFile={allowMultiFile}
          images={images}
          maxPhotoLength={maxPhotoLength}
          formats={formats}
          maxSize={maxSize}
          loading={loading}
          sendAnalytics={sendAnalytics}
        />
      </div>
      {listType === 'list' && (
        <ImageList
          images={images}
          onDeleteImage={onDeleteImage}
          onReorderImageUp={onReorderImageUp}
          onReorderImageDown={onReorderImageDown}
          loading={loading}
          sendAnalytics={sendAnalytics}
        />
      )}
      {listType === 'grid' && (
        <SortableImageGrid
          images={images}
          onReorderSave={onReorderSave}
          showReorderSave={showReorderSave}
          onReorder={onReorder}
          onDelete={onDeleteImage}
          getLargeUrl={getLargeUrl}
          columns={columns}
          sendAnalytics={sendAnalytics}
        />
      )}
    </>
  );
};

export default MediaUpload;
