// -----------------------------------------footer content and constants------------------------------------------------

export const adminUserType = 'atc_admin';

export const copyrightStatement = '©' + new Date().getFullYear() + ' Autotrader, Inc. All Rights Reserved. "Autotrader" is a registered trademark of TPI Holdings, Inc. used under exclusive license.';

export const acceptTermStatement = 'By using this service, you accept the terms of our';

export const visitorAgreement = 'Visitor Agreement';

export const privacyStatement = 'Privacy Statement';

export const visitorAgreementUrl = 'https://www.coxautoinc.com/terms/wp-content/uploads/sites/3/Common-Customer-TOU.pdf';

export const privacyStatementUrl = 'http://www.coxautoinc.com/privacy-statement';

export const adChoices = 'AdChoices';

export const adChoicesUrl = 'http://info.evidon.com/pub_info/413?v=1';

export const adChoicesIconSrcUrl = 'https://c.betrad.com/pub/icon1.png';

export const autotrader = 'Autotrader';

export const autotraderUrl = 'https://www.autotrader.com';