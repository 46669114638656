import React from "react";
import MediaCarousel from "../../reusableComponents/MediaCarousel/MediaCarousel";
import PropsDescription from "../../components/PropsDescription/PropsDescription";
import "./MediaCarouselContainer.css";
import mediaDataMock from "./mediaDataMock.json";

const MediaCarouselContainer = () => {
  const MediaCarouselProps = [
    {
      name: "title",
      type: "string",
      notes: "Title of card",
    },
    {
      name: "images",
      type: "array of objects",
      notes: "Image objects have url and thumbnailUrl props",
    },
    {
      name: "videos",
      type: "array of objects",
      notes: "Video objects have url prop",
    },
    {
      name: "linkName",
      type: "string",
      notes: "Display name of upload link. Upload link is not displayed if linkName is not provided.",
    },
    {
      name: "onLinkClick",
      type: "function",
      notes: "On click handler for upload link",
    },
    {
      name: "infoMsg",
      type: "string",
      notes: "Info message section is shown if infoMsg is provided.",
    },
  ];

  const onUploadClick = () => {
    console.log("navigate to upload page");
  };

  return (
    <div className="page-component-spacing ">
      <h4>Media Carousel Component</h4>
      <div className="media-carousel-container">
        <MediaCarousel
          title="Vehicle Photos and Videos"
          images={mediaDataMock.images}
          videos={mediaDataMock.videos}
          linkName="Manage Vehicle Photos"
          onLinkClick={onUploadClick}
          infoMsg="Some info here about your vehicle..."
        ></MediaCarousel>
      </div>
      <p>Note: Must include nSemble web components for buttons to display correctly.</p>
      <pre className="upload-code-block">
        <div>
          &lt;script type=&quot;text/javascript&quot;
          src=&quot;https://web-components.ddcplatform.dealer.com/nsemble-baseline.js&quot;&gt;&lt;/script/&gt;
        </div>
        <div>
          &lt;script type=&quot;text/javascript&quot;
          src=&quot;https://web-components.ddcplatform.dealer.com/nsemble-polyfills.js&quot;&gt;&lt;/script/&gt;
        </div>
      </pre>
      <p>
        To ensure the most up-to-date version of nSemble, cachebusting should be implemented in any app using these
        components. It is recommended to set the cache busting refresh time to about one hour to prevent loading the
        nSemble assets too many times.
      </p>
      <p>Please refer to:</p>
      <a href="https://ghe.coxautoinc.com/DDC-Dont-Panic/nsemble-web-components/tree/development#documentation-and-asset-urls">
        nSemble Documentation and Asset URLs Guide
      </a>
      <div className="page-component-spacing">
        <PropsDescription componentProps={MediaCarouselProps} />
      </div>
    </div>
  );
};

export default MediaCarouselContainer;
