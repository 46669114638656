import React from 'react';
import PropTypes from 'prop-types';

import NavigationBar from '../NavigationBar/NavigationBar';
import NavigationItem from '../NavigationItem/NavigationItem';
import NavigationDropdown from '../NavigationDropdown/NavigationDropdown';
import NavigationDropdownItem from '../NavigationDropdownItem/NavigationDropdownItem';

import BridgeBar from './BridgeBar';
import UniversalLink from '../UniversalLink/UniversalLink';
import './Header.css';

const Header = ({
  menuResponse,
  navHeaderLinkBaseUrl,
  logOutUser,
  loggingOut = false,
  env,
  sessionId,
  sendAnalytics,
}) => {
  const { userLogin, dealerName, navHeaders, userType } = menuResponse;
  const isAdmin = userType.toLowerCase() === 'atc_admin' ? true : false;

  const onSignOut = (isAdmin, targetUrl) => {
    if (isAdmin && sendAnalytics?.sendAdminLogOutEvent) sendAnalytics.sendAdminLogOutEvent();
    if (!isAdmin && sendAnalytics?.sendLogOutEvent) sendAnalytics.sendLogOutEvent();

    logOutUser(isAdmin, isAdmin ? navHeaderLinkBaseUrl : targetUrl);
  };

  const getUrlExtension = (navItem) => {
    if (!navItem || !navItem.micrositeDomain) {
      return '';
    }

    if (navItem.micrositeDomain.toLowerCase().includes(window.location.hostname)) {
      return '';
    }

    return '.html';
  };

  const NavLinkItem = (navItem, index, dsBaseUrl) => {
    if (!navItem.visible) return;

    return (
      <NavigationDropdownItem
        key={`nav-dropdown-item-${index}`}
        href={'0'}
        onClick={() => {
          if (sendAnalytics?.sendNavClickEvent) sendAnalytics?.sendNavClickEvent(navItem.navItemTitle);
        }}
      >
        <UniversalLink
          href={`${navItem.microsite ? navItem.micrositeDomain : dsBaseUrl}${navItem.href}${getUrlExtension(navItem)}`}
        >
          {navItem.navItemTitle}
        </UniversalLink>
      </NavigationDropdownItem>
    );
  };

  const createNavDropDown = (nav, index, dsBaseUrl) => {
    return (
      <NavigationDropdown>{nav.navItems.map((navItem, i) => NavLinkItem(navItem, i, dsBaseUrl))}</NavigationDropdown>
    );
  };

  return (
    <div className="header-container">
      {loggingOut && (
        <div className="logout-user">
          Logging out user<span>.</span>
          <span>.</span>
          <span>.</span>
        </div>
      )}
      {!isAdmin && (
        <BridgeBar
          sessionId={sessionId}
          logOutUser={onSignOut}
          env={env}
        />
      )}
      <div className="user-details-container">
        <div className="header-user-info flex-row-header">
          {isAdmin && (
            <div className="header-links admin-header">
              <div>
                <a
                  href={navHeaderLinkBaseUrl + '/dc/agent/agent-home.jsf'}
                  onClick={sendAnalytics?.sendAgentHomeClickEvent}
                >
                  Agent Home
                </a>
              </div>
              <div className="header-user-info-links-separator">|</div>
              <div>
                <a
                  href={navHeaderLinkBaseUrl + '/dc/dashboard/dashboard.jsf'}
                  onClick={sendAnalytics?.sendDashboardClickEvent}
                >
                  {dealerName}
                </a>
              </div>
              <div className="remove-user-link">
                <a
                  href={navHeaderLinkBaseUrl + '/dc/agent/agent-home.jsf'}
                  onClick={sendAnalytics?.sendContactUsClickEvent}
                >
                  x
                </a>
              </div>
            </div>
          )}

          <div className="user-details-wrapper">
            {userLogin +
              ', ' +
              (isAdmin ? '' : dealerName) +
              ' signed in: ' +
              new Date().toLocaleString([], {
                hour: '2-digit',
                minute: '2-digit',
              })}
          </div>

          <div className="header-user-info-separator">|</div>
          <div className="header-links">
            {isAdmin && (
              <div
                className="sign-out-container"
                onClick={() => onSignOut(true)}
              >
                <span>Sign Out</span>
              </div>
            )}
            {isAdmin && <div className="header-user-info-links-separator">|</div>}
            <div>
              <a
                href={navHeaderLinkBaseUrl + '/dc/administration/administration.jsf'}
                onClick={sendAnalytics?.sendManageAccountClickEvent}
              >
                Manage Account
              </a>
            </div>
            <div className="header-user-info-links-separator">|</div>
            <div>
              <a
                href={navHeaderLinkBaseUrl + '/dc/contactUs'}
                onClick={sendAnalytics?.sendContactUsClickEvent}
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
      <NavigationBar brandLogoUrl="//www.autotrader.com/resources/img/svgicons/atc-logo.svg">
        {navHeaders.map((nav, index) => {
          return (
            <NavigationItem
              className="navbar-header-link"
              key={`nav-item-${index}`}
              dropdown={nav.navItems.length > 0}
              onClick={() => {
                if (sendAnalytics?.sendNavClickEvent) sendAnalytics?.sendNavClickEvent(nav.navTitle);
              }}
            >
              {nav.navItems.length > 0 ? (
                <>
                  <div>
                    {nav.navTitle}
                    <i className="icon icon-minimal-down dropdown-icon" />
                  </div>
                  <>{nav.navItems.length > 0 && createNavDropDown(nav, index, navHeaderLinkBaseUrl)}</>
                </>
              ) : (
                <>
                  <UniversalLink
                    href={`${nav.microsite ? nav.micrositeDomain : navHeaderLinkBaseUrl}${nav.href}${getUrlExtension(
                      nav
                    )}`}
                  >
                    {nav.navTitle.toLowerCase() === 'advanced search' ? nav.navTitle + ' >' : nav.navTitle}
                  </UniversalLink>
                </>
              )}
            </NavigationItem>
          );
        })}
      </NavigationBar>
    </div>
  );
};

Header.propTypes = {
  menuResponse: PropTypes.shape({
    userLogin: PropTypes.string.isRequired,
    dealerName: PropTypes.string.isRequired,
    userType: PropTypes.string.isRequired,
    navHeaders: PropTypes.arrayOf(
      PropTypes.shape({
        navTitle: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
        navItems: PropTypes.arrayOf(
          PropTypes.shape({
            navItemTitle: PropTypes.string,
            href: PropTypes.string,
            visible: PropTypes.bool,
          })
        ).isRequired,
      })
    ).isRequired,
  }).isRequired,
  navHeaderLinkBaseUrl: PropTypes.string.isRequired,
  logOutUser: PropTypes.func,
  loggingOut: PropTypes.bool,
  env: PropTypes.string.isRequired,
  sessionId: PropTypes.string.isRequired,
  sendAnalytics: PropTypes.object,
};

export default Header;
