import React from 'react';
import { Lock } from '../../reusableComponents';
import { LockStatus } from '../../reusableComponents/lib/constants';
import styles from './LockContainer.module.scss';

export default function LockContainer() {
  return (
    <div className="page-component-spacing">
      <h4>Lock</h4>
      <div className={styles.row}>
        Locked: <Lock lockStatus={LockStatus.LOCKED} />
      </div>
      <div className={styles.row}>
        Unlocked: <Lock lockStatus={LockStatus.UNLOCKED} />
      </div>
      <div className={styles.row}>
        Unselected: <Lock lockStatus={LockStatus.UNSELECTED} />
      </div>
    </div>
  );
}
