import React from 'react';
import Tabs from '../../reusableComponents/Tabs';
import Tab from '../../reusableComponents/Tab';

export default function TabsContainer() {
  return (
    <div className="page-component-spacing">
      <h4>Tabs</h4>
      <Tabs
        defaultActiveKey="profile"
        id="uncontrolled-tab-example"
      >
        <Tab
          eventKey="summary"
          title="Summary"
        >
          Tab content for Summary
        </Tab>
        <Tab
          eventKey="marketComparison"
          title="Market Comparison"
        >
          Tab content for Market Comparison
        </Tab>
        <Tab
          eventKey="Advertising"
          title="Advertising"
        >
          Tab content for Advertising
        </Tab>
      </Tabs>
    </div>
  );
}
