import React, { useMemo, forwardRef } from 'react';
import classNames from 'classnames';
import { compareUrl } from './compareUrl';
import { useLocation } from './useLocation';

const externalLinkAttributes = { rel: 'noopener', target: '_blank' };

export const NativeLink = forwardRef((
  {
      className,
      style,
      activeClassName,
      activeStyle,
      active,
      strict,
      exact,
      href,
      external,

      // unused, but excluded from ...rest:
      native,
      state,

      ...rest
  }, ref) => {
    const location = useLocation();
    // skip url compare when is irrelevant
    const shouldCalcActive = !!activeClassName || !!activeStyle;

    const isActive = useMemo(() => {
      if (!shouldCalcActive) return false;
      if (typeof active === 'boolean') return active;
      if (!location || !href) return false;

      return compareUrl(location.pathname, href, { exact, strict });
    }, [active, href, location, shouldCalcActive, exact, strict]);

    const externalProps = external ? externalLinkAttributes : {};
    const combinedStyles = useMemo(
      () => (isActive && activeStyle ? { ...style, ...activeStyle } : style),
      [isActive, style, activeStyle]
    );

    return (
      // eslint-disable-next-line
      <a 
        {...externalProps}
        {...rest}
        ref={ref}
        href={href}
        className={classNames(className, isActive && activeClassName)}
        style={combinedStyles}
      />
    );
  }
);