import { useNavigation } from './NavigationProvider';

export const useLocation = () =>{
  const nav = useNavigation();
  const actualUseLocation = nav.useLocation || NativeUseLocation;

  return actualUseLocation();
}

const NativeUseLocation = () => {
  if (typeof window === 'undefined') return undefined;
  return window.location;
}