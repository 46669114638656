export const menuResponse = {
    "navHeaders": [
        {
            "navTitle": "Dashboard",
            "href": "/dc/dashboard/dashboard.jsf",
            "navItems": []
        },
        {
            "navTitle": "Vehicles",
            "href": "/dc/vehicles/vehicle-list.jsf",
            "navItems": [
                {
                    "navItemTitle": "Inventory List",
                    "href": "/dc/vehicles/vehicle-list.jsf",
                    "visible": true
                },
                {
                    "navItemTitle": "Digital Transactions",
                    "href": "/microsite/digital-transactions",
                    "visible": true,
                    "microsite": true,
                    "micrositeDomain": "https://vehicle-details.awsatcdsnp.autotrader.com"
                },
                {
                    "navItemTitle": "Listing Logic",
                    "href": "/dc/vehicles/listing-logic-dashboard.jsf",
                    "visible": true
                },
                {
                    "navItemTitle": "Pricing",
                    "href": "/dc/vehicles/fpp-list.jsf",
                    "visible": true
                },
                {
                    "navItemTitle": "OEM Offer groups",
                    "href": "/dc/vehicles/vehicle-group-match.jsf",
                    "visible": true
                }
            ]
        },
        {
            "navTitle": "Prospects",
            "href": "/dc/prospects/prospects.jsf",
            "navItems": []
        },
        {
            "navTitle": "Advertising Products",
            "href": "/merchandising/comment-builder.xhtml",
            "navItems": [
                {
                    "navItemTitle": "Dealership Merchandising",
                    "href": "/merchandising/comment-builder.xhtml",
                    "visible": true
                },
                {
                    "navItemTitle": "Spotlight Ads",
                    "href": "/dc/advertising/spotlights/spotlight-ad-main.jsf",
                    "visible": true
                },
                {
                    "navItemTitle": "KBB Service Advisor",
                    "href": "/microsite/auto-repair-center",
                    "visible": false
                },
                {
                    "navItemTitle": "Offers",
                    "href": "/dc/advertising/newspecials/vehicle-specials-new.jsf",
                    "visible": true
                }
            ]
        },
        {
            "navTitle": "Multimedia",
            "href": "/dc/media/media-video.jsf",
            "navItems": [
                {
                    "navItemTitle": "Audio and Video Library",
                    "href": "/dc/media/media-video.jsf",
                    "visible": true
                }
            ]
        },
        {
            "navTitle": "Reporting",
            "href": "/dc/reporting/reporting-executive-summary.jsf",
            "navItems": [
                {
                    "navItemTitle": "Dealer Activity Report",
                    "href": "/dc/reporting/reporting-coop-reports.jsf",
                    "visible": true
                },
                {
                    "navItemTitle": "Co op reports",
                    "href": "/dc/reporting/reporting-executive-summary.jsf",
                    "visible": false
                }
            ]
        },
        {
            "navTitle": "Advanced Search",
            "href": "/dc/search/advanced-search.jsf",
            "navItems": []
        }
    ],
    "dealerId": 607901,
    "dealerName": "Prestige Lexus",
    "sessionId": "1234",
    "userEmail": "jay.hammond@coxautoinc.com",
    "userId": "0841f068-d36b-45b5-9be7-5ef3a3132f2a",
    "userLogin": "Order66",
    "userPhone": null,
    "userPlatformId": null,
    "userType": "DS_DEALER_ADMIN"
};

export const atcAdminMenuResponse = {
    "dealerId": 2000155624,
    "dealerName": "Dwaraka 3",
    "sessionId": "4faba68a",
    "userEmail": "",
    "userId": "95713",
    "userLogin": "qasuperuser",
    "userPhone": null,
    "userPlatformId": null,
    "userType": "ATC_ADMIN",
    "navHeaders": [
        {
            "navTitle": "Dashboard",
            "href": "/dc/dashboard/dashboard.jsf",
            "navItems": []
        },
        {
            "navTitle": "Vehicles",
            "href": "/dc/vehicles/vehicle-list.jsf",
            "navItems": [
                {
                    "navItemTitle": "Inventory List",
                    "href": "/dc/vehicles/vehicle-list.jsf",
                    "visible": true
                },
                {
                    "navItemTitle": "Listing logic",
                    "href": "/dc/vehicles/listing-logic-dashboard.jsf",
                    "visible": true
                },
                {
                    "navItemTitle": "Pricing",
                    "href": "/dc/vehicles/fpp-list.jsf",
                    "visible": true
                },
                {
                    "navItemTitle": "OEM Offer groups",
                    "href": "/dc/vehicles/vehicle-group-match.jsf",
                    "visible": true
                }
            ]
        },
        {
            "navTitle": "Prospects",
            "href": "/dc/prospects/prospects.jsf",
            "navItems": []
        },
        {
            "navTitle": "Advertising Products",
            "href": "/merchandising/comment-builder.xhtml",
            "navItems": [
                {
                    "navItemTitle": "Dealership Merchandising",
                    "href": "/merchandising/comment-builder.xhtml",
                    "visible": true
                },
                {
                    "navItemTitle": "Spotlight Ads",
                    "href": "/dc/advertising/spotlights/spotlight-ad-main.jsf",
                    "visible": true
                },
                {
                    "navItemTitle": "Auto Repair Center",
                    "href": "/dc/advertising/auto-repair-center.jsf",
                    "visible": true
                },
                {
                    "navItemTitle": "Offers",
                    "href": "/dc/advertising/newspecials/vehicle-specials-new.jsf",
                    "visible": true
                }
            ]
        },
        {
            "navTitle": "Multimedia",
            "href": "/dc/media/multimedia-photos.jsf",
            "navItems": [
                {
                    "navItemTitle": "Vehicle Photos",
                    "href": "/dc/media/multimedia-photos.jsf",
                    "visible": true
                },
                {
                    "navItemTitle": "Audio and Video Library",
                    "href": "/dc/media/media-video.jsf",
                    "visible": true
                }
            ]
        },
        {
            "navTitle": "Reporting",
            "href": "/dc/reporting/reporting-executive-summary.jsf",
            "navItems": [
                {
                    "navItemTitle": "Dealer Activity Report",
                    "href": "/dc/reporting/reporting-executive-summary.jsf",
                    "visible": true
                },
                {
                    "navItemTitle": "Co op reports",
                    "href": "/dc/reporting/reporting-coop-reports.jsf",
                    "visible": false
                }
            ]
        },
        {
            "navTitle": "Advanced Search",
            "href": "/dc/search/advanced-search.jsf",
            "navItems": []
        }
    ]
};

export const logOutUser = async () => {
    console.log('Log out invoked!');
};

export const sideNavResponse = [
    {
        "sideNavigationTitle": "Messaging",
        "sideNavigationLinks": [
            {
                "linkTitle": "Comment Builder",
                "href": "/merchandising/comment-builder.xhtml",
                "visible": true
            },
            {
                "linkTitle": "Disclaimer & Dealer Comments",
                "href": "/merchandising/disclaimer.xhtml",
                "visible": true
            },
            {
                "linkTitle": "Why Buy From Me",
                "href": "/merchandising/why-buy.xhtml",
                "visible": true
            },
            {
                "linkTitle": "Hours of Operation",
                "href": "/merchandising/hours-of-operation.xhtml",
                "visible": true
            },
            {
                "linkTitle": "Services & Specialties",
                "href": "/merchandising/services.xhtml",
                "visible": true
            },
            {
                "linkTitle": "Home Services",
                "href": "/merchandising/home-services.xhtml",
                "visible": true
            },
            {
                "linkTitle": "Limited Warranty",
                "href": "/microsite/limited-warranty",
                "microsite": true,
                "visible": true
            },
            {
                "linkTitle": "Vehicle Return Policy",
                "href": "/merchandising/vehicle-return-policy.xhtml",
                "visible": true
            },
            {
                "linkTitle": "Dealer Taglines",
                "href": "/merchandising/dealer-taglines.xhtml",
                "visible": true
            },
            {
                "linkTitle": "Awards",
                "href": "/merchandising/awards.xhtml",
                "visible": true
            },
            {
                "linkTitle": "Why Buy This Model",
                "href": "/merchandising/why-buy-this-model.xhtml",
                "visible": false
            }
        ]
    },
    {
        "sideNavigationTitle": "Graphics",
        "sideNavigationLinks": [
            {
                "linkTitle": "Stock Image",
                "href": "/merchandising/stock-image.xhtml",
                "visible": true
            },
            {
                "linkTitle": "Dealership Details Page Videos",
                "href": "/merchandising/ddp-videos.xhtml",
                "visible": true
            },
            {
                "linkTitle": "Dealership Photos",
                "href": "/microsite/dealership-photos",
                "microsite": true,
                "visible": true
            },
            {
                "linkTitle": "Leaderboards",
                "href": "/merchandising/leaderboards.xhtml",
                "visible": true
            },
            {
                "linkTitle": "KBB Graphics",
                "href": "/merchandising/kbb-graphics.xhtml",
                "visible": true
            }
        ]
    },
    {
        "sideNavigationTitle": "Offer Settings",
        "sideNavigationLinks": [
            {
                "linkTitle": "Custom Offer Disclaimers",
                "href": "/merchandising/custom-offer-disclaimers.xhtml",
                "visible": false
            },
            {
                "linkTitle": "OEM Offer Disclaimers",
                "href": "/merchandising/oem-offer-disclaimers.xhtml",
                "visible": false
            },
            {
                "linkTitle": "OEM Offer Settings",
                "href": "/merchandising/oem-offer-settings.xhtml",
                "visible": false
            },
            {
                "linkTitle": "Price Markdown",
                "href": "/merchandising/price-markdown.xhtml",
                "visible": false
            }
        ]
    },
    {
        "sideNavigationTitle": "Tier Governor",
        "sideNavigationLinks": [
            {
                "linkTitle": "View Vehicle Sorting Settings",
                "href": "/merchandising/tier-governor-settings.xhtml",
                "visible": true
            }
        ]
    },
    {
        "sideNavigationTitle": "Vehicle History Report",
        "sideNavigationLinks": [
            {
                "linkTitle": "Vehicle History Report",
                "href": "/merchandising/vhr.xhtml",
                "visible": true
            }
        ]
    },
    {
        "sideNavigationTitle": "Vehicle Spotlights",
        "sideNavigationLinks": [
            {
                "linkTitle": "Auto Swap Emails",
                "href": "/merchandising/auto-swap-emails.xhtml",
                "visible": false
            },
            {
                "linkTitle": "Email Alerts",
                "href": "/merchandising/email-alerts.xhtml",
                "visible": true
            }
        ]
    }
];
