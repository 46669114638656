import React from 'react';

export default function VideoTag ({ color = '#00528A', ...rest }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0 3C0 1.34315 1.34315 0 3 0H29.4054C30.8384 0 32 1.16164 32 2.59459V2.59459C32 18.8348 18.8348 32 2.59459 32V32C1.16164 32 0 30.8384 0 29.4054V3Z"
        fill={color}
      />
      <rect
        x="5"
        y="7"
        width="12"
        height="12"
        rx="3"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M18.5765 10.6396L21.5765 9.23697C22.2395 8.92698 23 9.41093 23 10.1429V16.0743C23 16.778 22.2926 17.2616 21.637 17.0061L18.637 15.8373C18.2529 15.6877 18 15.3177 18 14.9055V11.5455C18 11.1572 18.2247 10.804 18.5765 10.6396Z"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};
