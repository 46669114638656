import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ThumbnailItem from './ThumbnailItem';
import Modal from '../Modal/Modal';

import './ImageList.css';

const ImageList = ({ images, onDeleteImage, onReorderImageUp, onReorderImageDown, loading, sendAnalytics }) => {
  const [imageToDelete, setImageToDelete] = useState(null);
  const [deleting, setDeleting] = useState({});

  const onDeleteButton = async (mediaAssetId) => {
    setImageToDelete(null);
    setDeleting({ ...deleting, [mediaAssetId]: true });
    await onDeleteImage(mediaAssetId);
    setDeleting({ ...deleting, [mediaAssetId]: false });
  };

  const cancelDeleteModal = () => {
    setImageToDelete(null);
    if (sendAnalytics?.sendCancelDeleteModalEvent) {
      sendAnalytics.sendCancelDeleteModalEvent();
    }
  };

  const openDeleteModal = (imageId) => {
    setImageToDelete(imageId);
    if (sendAnalytics?.sendOpenDeleteModalEvent) {
      sendAnalytics?.sendOpenDeleteModalEvent();
    }
  };

  return (
    <>
      {!loading && images && images.length === 0 && (
        <p
          className="no-images-text"
          data-testid="no-images-msg"
        >
          You currently have no images
        </p>
      )}
      {images && images.length !== 0 && (
        <ul
          className="imagelist-container"
          data-testid="imagelist-container"
        >
          {images &&
            images.map((image, idx) => (
              <ThumbnailItem
                key={image.id}
                mediaAsset={image}
                first={idx === 0}
                last={idx === images?.length - 1}
                onDelete={() => openDeleteModal(image.id)}
                onReorderUp={() => onReorderImageUp(idx)}
                onReorderDown={() => onReorderImageDown(idx)}
                onDeleting={deleting[image.id]}
                loading={loading}
              />
            ))}
        </ul>
      )}

      <Modal
        open={imageToDelete != null}
        onClose={cancelDeleteModal}
        closeIcon={false}
      >
        <div className="delete-image-modal" data-testid="delete-image-modal">
          <button
            className="imagelist-back-button"
            data-testid="imagelist-back-button"
            onClick={cancelDeleteModal}
          >
            <i className={`icon icon-16 icon-e-remove-1`} />
          </button>
          <h4>Remove Photo</h4>
          <p>
            Please confirm that you want to delete this photo from your uploads.
          </p>
          <div className="imagelist-button-list">
            <button
              className="imagelist-cancel-button"
              data-testid="cancel-button"
              onClick={cancelDeleteModal}
            >
              Cancel
            </button>
            <button
              className="imagelist-delete-button"
              data-testid="delete-button"
              onClick={() => onDeleteButton(imageToDelete)}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

ImageList.propTypes = {
  images: PropTypes.array,
  onDeleteImage: PropTypes.func,
  onReorderImageUp: PropTypes.func,
  onReorderImageDown: PropTypes.func,
  loading: PropTypes.bool,
  sendAnalytics: PropTypes.object,
};

export default ImageList;
