import React, { useRef, useEffect, useState } from 'react';
import Modal from '../Modal/Modal';
import useFileQueue from '../hooks/useFileQueue';
import Alerts from '../Alerts/Alerts';
import Spinner from '../Spinner/Spinner';
import PropTypes from 'prop-types';
import { unzipFiles } from '../utilFunctions/fileHandlers';

import './DragAndDrop.css';

const DragAndDrop = ({
  onUpload,
  onUploadQueueComplete,
  allowMultiFile = false,
  maxPhotoLength,
  images,
  formats = ['.jpeg', '.jpg', '.gif', '.png'],
  maxSize = 5200000,
  loading,
  sendAnalytics,
}) => {
  const dropzone = useRef(null);
  const inputFile = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMsg, setModalMsg] = useState('');
  const [atMaxImages, setAtMaxImages] = useState(false);
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);
  const [erroredImgs, setErroredImgs] = useState([]);

  const imageErrorMsgs = {
    imageTypeNotSupported: {
      modalMsg: `Sorry, the photo you tried to upload is not supported. Please make sure the photo is formatted as a ${formats.join(
        ', '
      )} file and no larger than ${bytesToMb(maxSize)}MB`,
      consoleLogMsg: `Only following file formats are acceptable: ${formats.join(', ')}`,
      multiFileMsg: `Only following file formats are acceptable: ${formats.join(', ')}`,
    },
    imageSizeNotSupported: {
      modalMsg: `Sorry, the photo you tried to upload is not supported. Please make sure the photo is formatted as a ${formats.join(
        ', '
      )} file and no larger than ${bytesToMb(maxSize)}MB`,
      consoleLogMsg: `Max size limit exceeded: ${bytesToMb(maxSize)}MB`,
      multiFileMsg: `Max size limit exceeded: ${bytesToMb(maxSize)}MB`,
    },
    multiFileNotAllowed: {
      modalMsg: 'Sorry, only 1 photo can be uploaded at a time',
      consoleLogMsg: 'Only 1 photo can be uploaded at a time',
    },
    imageLimitExceeded: {
      modalMsg: `Sorry, uploading these images will exceed the image limit of ${maxPhotoLength}`,
      consoleLogMsg: 'Maximum image number exceeded',
    },
  };

  /**
   * Checks that the image type and image size are acceptable. Also verifies that file length is 1 when multi file is not allowed
   * @param {File} file File object being uploaded
   * @returns The returned object from uploadFile if file is acceptable; otherwise, returns null
   */
  const processImage = (file) => {
    if (atMaxImages) {
      return;
    }

    // Check Image Type
    if (formats && !formats.some((format) => file.name.toLowerCase().endsWith(format.toLowerCase()))) {
      console.log(imageErrorMsgs.imageTypeNotSupported.consoleLogMsg);
      if (!allowMultiFile) {
        openModal('Photo Upload Not Supported', imageErrorMsgs.imageTypeNotSupported.modalMsg);
      } else {
        setErroredImgs((currentErroredImgs) => [
          ...currentErroredImgs,
          {
            file: file,
            msg: imageErrorMsgs.imageTypeNotSupported.multiFileMsg,
          },
        ]);
      }
      return;
    }

    // Check Image Size
    if (maxSize && file.size > maxSize) {
      console.log(imageErrorMsgs.imageSizeNotSupported.consoleLogMsg);
      if (!allowMultiFile) {
        openModal('Photo Upload Not Supported', imageErrorMsgs.imageSizeNotSupported.modalMsg);
      } else {
        setErroredImgs((currentErroredImgs) => [
          ...currentErroredImgs,
          {
            file: file,
            msg: imageErrorMsgs.imageSizeNotSupported.multiFileMsg,
          },
        ]);
      }
      return;
    }

    // Check files uploaded == 1 if multi file upload is not allowed
    if (files.length > 1 && !allowMultiFile) {
      console.log(imageErrorMsgs.multiFileNotAllowed.consoleLogMsg);
      openModal('Photo Upload Not Supported', imageErrorMsgs.multiFileNotAllowed.modalMsg);
      return;
    }

    return onUpload(file);
  };

  const { files, isProcessing, addFile, done, totalFileCount, erroredFile } = useFileQueue(processImage);

  useEffect(() => {
    if ((maxPhotoLength && images && images.length >= maxPhotoLength) || maxPhotoLength === 0) {
      setAtMaxImages(true);
    } else {
      setAtMaxImages(false);
    }
  }, [images, maxPhotoLength]);

  useEffect(() => {
    const showSuccessIcon = () => {
      setIsUploadSuccess(true);
      // Display success icon for one second when processing queue is completed
      setTimeout(() => {
        setIsUploadSuccess(false);
      }, 1000);
    };

    if (done) {
      if (onUploadQueueComplete) {
        onUploadQueueComplete()
          .then(() => {
            showSuccessIcon();
          })
          .catch((err) => {
            console.log('error: ', err);
          });
      } else {
        showSuccessIcon();
      }
    }
  }, [done, onUploadQueueComplete]);

  useEffect(() => {
    if (erroredFile) {
      setErroredImgs((currentErroredImgs) => [
        ...currentErroredImgs,
        { file: erroredFile, msg: 'There was an error uploading the image' },
      ]);
    }
  }, [erroredFile]);

  /**
   * Unzips file if file type is ZIP. Checks that files added will not exceed maximum image limit. Adds files to processing queue
   * @param {Array} newFiles Array of files
   * @returns null
   */
  const handleAddFiles = async (newFiles) => {
    // Reset error messages
    if (!files.length) {
      setErroredImgs([]);
    }

    let filesToAdd = [];
    for (let i = 0; i < newFiles.length; i++) {
      if (newFiles[i].name.toLowerCase().endsWith('.zip')) {
        const unzippedFiles = await unzipFiles(newFiles[i]);
        for (let j = 0; j < unzippedFiles.length; j++) {
          filesToAdd.push(unzippedFiles[j]);
        }
      } else {
        filesToAdd.push(newFiles[i]);
      }
    }

    // Check if number of images in batch will exceed photo limit
    if (maxPhotoLength && filesToAdd.length + images.length + totalFileCount > maxPhotoLength) {
      console.log(imageErrorMsgs.imageLimitExceeded.consoleLogMsg);
      openModal('Photo Upload Not Supported', imageErrorMsgs.imageLimitExceeded.modalMsg);
      return;
    }

    for (let fileToAdd in filesToAdd) {
      addFile(filesToAdd[fileToAdd]);
    }
  };

  const cancelDefaultEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (sendAnalytics?.sendDropzoneDropEvent) {
      sendAnalytics.sendDropzoneDropEvent();
    }

    const files = [...e.dataTransfer.files];
    handleAddFiles(files);
  };

  const onDropZoneClick = (e) => {
    if (sendAnalytics?.sendDropzoneClickEvent) {
      sendAnalytics.sendDropzoneClickEvent();
    }
    if (atMaxImages) return;
    inputFile.current.click();
  };

  const onFileSelected = (e) => {
    const files = [...e.target.files];
    handleAddFiles(files);
  };

  const openModal = (title, msg) => {
    setModalTitle(title);
    setModalMsg(msg);
    setShowModal(true);
  };

  function bytesToMb(bytes) {
    return Math.round(bytes / Math.pow(10, 6), 2);
  }

  function displayDropzoneIcon() {
    if (isProcessing) {
      return (
        <div className="dropzone-spinner">
          <Spinner
            height="80px"
            width="80px"
            radius="30"
          />
        </div>
      );
    }

    if (isUploadSuccess) {
      return (
        <div className="dropzone-upload-icon-container">
          <div className="dropzone-icon-background--success">
            <i className="icon icon-32 icon-check dropzone-upload-icon--success" />
          </div>
        </div>
      );
    }

    return (
      <div className="dropzone-upload-icon-container">
        <div className={`dropzone-icon-background${atMaxImages ? '--maxed' : ''}`}>
          <i className={`icon icon-32 icon-data-upload dropzone-upload-icon${atMaxImages ? '--maxed' : ''}`} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div
        ref={dropzone}
        data-testid="dropzone-container"
        className={`dropzone-container${atMaxImages ? '--maxed' : ''}`}
        onClick={(e) => {
          if ((isProcessing && !allowMultiFile) || loading) return;
          else onDropZoneClick(e);
        }}
        onDragOver={cancelDefaultEvent}
        onDrop={(e) => {
          if ((isProcessing && !allowMultiFile) || loading) cancelDefaultEvent(e);
          else onDrop(e);
        }}
      >
        <div className="dropzone-content">
          {displayDropzoneIcon()}

          {allowMultiFile && totalFileCount > 0 && (
            <p className="dropzone-heading">{`Uploading ${
              totalFileCount - files.length + 1
            } of ${totalFileCount} photos...`}</p>
          )}

          {isUploadSuccess && !isProcessing && <p className="dropzone-heading--success">Complete</p>}

          <p className="dropzone-heading">
            {atMaxImages
              ? 'Maximum photo upload reached, please remove image(s) to add more'
              : 'Click to upload, or drag and drop your image here'}
          </p>

          <p className="dropzone-subheading">
            {`Photos must be in ${formats.join(', ')} file format and no larger than ${bytesToMb(maxSize)}MB`}
          </p>
        </div>
      </div>
      {erroredImgs.length > 0 && (
        <div className="dropzone-multierror-container">
          {erroredImgs.map((errorMsg, i) => {
            return (
              <div
                key={i}
                data-testid="dropzone-multierror-item"
                className="dropzone-multierror-item"
              >
                <Alerts
                  type="error"
                  dismissible={true}
                >
                  <span>
                    File name: <b>{errorMsg.file.name}</b>. Error: {errorMsg.msg}
                  </span>
                </Alerts>
              </div>
            );
          })}
        </div>
      )}
      <input
        type="file"
        id="file"
        data-testid="dropzone-file"
        ref={inputFile}
        style={{ display: 'none' }}
        multiple
        accept={formats.join()}
        onChange={onFileSelected}
      />
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        closeIcon={false}
      >
        <button
          className="dropzone-cancel-button"
          data-testid="dropzone-cancel-button"
          onClick={() => setShowModal(false)}
        >
          <i className={`icon icon-16 icon-e-remove-1`} />
        </button>
        <p
          className="dropzone-modal-title"
          data-testid="dropzone-modal"
        >
          {modalTitle}
        </p>
        <p className="dropzone-modal-body">{modalMsg}</p>
        <div className="dropzone-modal-button-container">
          <button
            className="dropzone-modal-button"
            data-testid="dropzone-modal-button"
            onClick={() => setShowModal(false)}
          >
            Try Again
          </button>
        </div>
      </Modal>
    </div>
  );
};

DragAndDrop.propTypes = {
  onUpload: PropTypes.func,
  onUploadQueueComplete: PropTypes.func,
  images: PropTypes.array,
  maxPhotoLength: PropTypes.number,
  allowMultiFile: PropTypes.bool,
  formats: PropTypes.array,
  maxSize: PropTypes.number,
  loading: PropTypes.bool,
  sendAnalytics: PropTypes.object,
};

export default DragAndDrop;
