import React from 'react';
import { Divider } from '../../reusableComponents';
import styles from './DividerContainer.module.scss';

export default function DividerContainer() {
  return (
    <div className="page-component-spacing">
      <h4>Divider</h4>
      <div>
        <Divider />
      </div>
      <div>
        <Divider style={{ divider: styles.dividerDarker }} />
      </div>
      <div>
        <Divider style={{ divider: styles.dividerBlue }} />
      </div>
    </div>
  );
}
