import React from 'react';
import styles from './Modal.module.scss';
import Button from '../Button/Button';

const Modal = ({ open, onClose, title, closeIcon = true, style, children }) => {
  if (!open) {
    return null;
  }
  return (
    <>
      <div
        data-testid="modal-overlay"
        className={`${styles.modalBackdrop}`}
        onClick={onClose}
      />
      <div
        data-testid="modal"
        className={`${styles.modalContainer} ${style?.modalContainer || ''}`}
      >
        <div className={`${styles.title} ${style?.title || ''}`}>
          {title}
          {closeIcon && (
            <Button
              text
              class={`${styles.closeIcon} ${style?.closeIcon || ''}`}
              onClick={onClose}
            >
              <i className={`icon icon-16 icon-e-remove-1`} />
            </Button>
          )}
        </div>
        <div className={`${styles.body} ${style?.body || ''}`}>{children}</div>
      </div>
    </>
  );
};

export default Modal;
