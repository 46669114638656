import React from 'react';
import { default as BootstrapTabs } from 'react-bootstrap/Tabs';
import './Tabs.scss';

export default function Tabs(props) {
  return (
    <div className="tabs-container">
      <BootstrapTabs {...props} />
    </div>
  );
}
