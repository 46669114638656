import React from 'react';
import styles from './FormInput.module.scss';
import Lock from '../Lock';

export default function FormInput({
  type,
  label,
  name,
  value,
  placeholder,
  error,
  disabled,
  variant,
  autofocus,
  onInput,
  prefix,
  postfix,
  required,
  readOnly,
  lockStatus,
  onLockClick,
  className,
  style,
  ...rest
}) {
  return (
    <div className={`${styles.inputContainer} ${style?.inputContainer || ''}`}>
      {label && (
        <div className={`${styles.label} ${style?.label || ''}`}>
          {label} {required && <span className={styles.required}>*</span>}
        </div>
      )}
      <div className={`${styles.value} ${style?.value || ''}`}>
        {!readOnly && (
          <nsemble-input
            type={type}
            name={name}
            value={value}
            placeholder={placeholder ?? ''}
            error={error}
            disabled={disabled}
            variant={variant}
            autofocus={autofocus}
            onInput={onInput}
            prefix={prefix}
            postfix={postfix}
            class={className}
            {...rest}
          />
        )}
        {readOnly && <div>{value}</div>}
        <Lock
          lockStatus={lockStatus}
          onClick={onLockClick}
        />
      </div>
    </div>
  );
}
