import React, { useState } from "react";
import "./NavigationBar.css";

const NavigationBar = ({ brandLogoUrl, children }) => {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <nav className="navbar-container">
      <div className="navbar-content">
        <div className="logo">
          <img
            src={brandLogoUrl}
            alt="Logo"
          />
        </div>
        <div className={`nav-elements ${showNavbar ? "active" : ""}`}>
          {children}
        </div>
        <div
          className="menu-icon"
          onClick={handleShowNavbar}
        >
          <i className={`icon icon-32 icon-menu-8`} />
        </div>
      </div>
    </nav>
  );
};

export default NavigationBar;
