import React from 'react';

export default function Question({ color = '#00528A', ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle
        cx="12"
        cy="12"
        r="9"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M9 9.94737C9 8.31958 10.3431 7 12 7C13.6569 7 15 8.31958 15 9.94737C15 10.848 14.5888 11.6543 13.9408 12.1949C13.0481 12.9398 12 13.8373 12 15V15"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="12"
        cy="18"
        r="1"
        fill={color}
      />
    </svg>
  );
}
