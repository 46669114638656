import React from 'react'
import ImageGrid from '../../reusableComponents/ImageGrid/ImageGrid';
import s3ImagesMock from './s3ImageMock.json';

const ImageGridContainer = () => {
    const getLargeUrl = (image) => {
        return image.imageUrl;
      }

    return (
        <div className="page-component-spacing">
            <ImageGrid images={s3ImagesMock.items} columns={5} getLargeUrl={getLargeUrl}/>
        </div>
    );
}

export default ImageGridContainer;
