import React from 'react';
import './Card.css';
import PropTypes from 'prop-types';

const Card = ({ children, title = '' }) => {
  return (
    <div
      className="ds-card"
      data-testid="ds-card"
    >
      {title && <div className="card-title">{title}</div>}
      <div className="card-content">{children}</div>
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default Card;
