import React from 'react';

const HomeContainer  =  () => {
    return (
        <div className="page-component-spacing">
            <h4>Consumer Platform UI Components</h4>
            <p>npm i consumer-platforms-ui-components</p>
        </div>
    );
}

export default HomeContainer;