import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { PlainImage } from './PlainImage';

export const SortableImage = (props) => {
  const sortable = useSortable({ id: props.id });
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = sortable;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <PlainImage
      ref={setNodeRef}
      style={style}
      {...props}
      {...attributes}
      {...listeners}
      data-testid="sortable-sortableimage"
    />
  );
};
