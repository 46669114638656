import React from 'react';

export default function LockOpen({ color = '#00528A', ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect
        x="5"
        y="11"
        width="14"
        height="10"
        rx="2"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M8 7V7C8 4.79086 9.79086 3 12 3V3C14.2091 3 16 4.79086 16 7V11"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="8"
        cy="7"
        r="1"
        fill={color}
      />
    </svg>
  );
}
