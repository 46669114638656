import React from 'react';
import { Button } from '../../reusableComponents';
import PropsDescription from '../../components/PropsDescription/PropsDescription';

export default function ButtonContainer() {
  const buttonProps = [
    {
      name: 'primary',
      type: 'boolean',
      notes: 'Primary style',
    },
    {
      name: 'secondary',
      type: 'boolean',
      notes: 'Secondary style',
    },
    {
      name: 'text',
      type: 'boolean',
      notes: 'Text style',
    },
    {
      name: 'disabled',
      type: 'boolean',
      notes: 'Disable button',
    },
    {
      name: 'onClick',
      type: 'function',
      notes: 'onClick action for button',
    },
  ];
  return (
    <div className="page-component-spacing">
      <h4>Button</h4>
      <div>
        <Button primary>Primary</Button>
      </div>
      <div>
        <Button secondary>Secondary</Button>
      </div>
      <div>
        <Button text>Text</Button>
      </div>
      <div>
        <Button disabled>Disabled</Button>
      </div>
      <div className="page-component-spacing">
        <PropsDescription componentProps={buttonProps} />
      </div>
    </div>
  );
}
