import React from 'react';

export default function Cancel ({ color = '#00528A', ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_69_326)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.20273 17.5128C9.24739 20.7146 14.3111 20.842 17.5129 17.7973C20.7146 14.7527 20.842 9.68894 17.7973 6.48718C14.7527 3.28542 9.68896 3.15806 6.4872 6.20271C3.28544 9.24736 3.15808 14.3111 6.20273 17.5128ZM4.75341 18.8911C8.55922 22.8933 14.8889 23.0525 18.8911 19.2466C22.8933 15.4408 23.0525 9.11117 19.2467 5.10897C15.4408 1.10677 9.1112 0.947571 5.109 4.75339C1.10679 8.5592 0.947591 14.8889 4.75341 18.8911ZM14.8284 7.75735C15.2189 7.36683 15.8521 7.36683 16.2426 7.75735C16.6332 8.14788 16.6332 8.78104 16.2426 9.17157L13.4142 12L16.2426 14.8284C16.6332 15.2189 16.6332 15.8521 16.2426 16.2426C15.8521 16.6332 15.2189 16.6332 14.8284 16.2426L12 13.4142L9.17157 16.2426C8.78104 16.6332 8.14788 16.6332 7.75736 16.2426C7.36683 15.8521 7.36683 15.2189 7.75736 14.8284L10.5858 12L7.75736 9.17157C7.36683 8.78104 7.36683 8.14788 7.75736 7.75735C8.14788 7.36683 8.78105 7.36683 9.17157 7.75735L12 10.5858L14.8284 7.75735Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_69_326">
          <rect
            width="24"
            height="24"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
