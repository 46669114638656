import * as jsZip from "jszip";

/**
 * Unzips a zip file object
 * @param {File} zip File object of type application/zip
 * @returns An array Files objects unzipped from the zipped file
 */
export const unzipFiles = async (zip) => {
  let unzippedFiles = [];
  const newZip = await jsZip.loadAsync(zip);
  for (const filename in newZip.files) {
    const fileData = await newZip.files[filename].async("blob");
    const unzippedFile = new File([fileData], filename);
    unzippedFiles.push(unzippedFile);
  };

  return unzippedFiles;
};
