import React from "react";
import "./NavigationItem.css";

const NavigationItem = ({ dropdown, children }) => {
  return (
    <>
      <div
        className={`nav-item-container ${dropdown ? "nav-item-dropdown" : ""}`}
        data-testid="nav-item-container"
      >
        {children}
      </div>
    </>
  );
};

export default NavigationItem;
