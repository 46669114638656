import React from 'react';

export default function VideoBigTag ({ color = '#00528A', ...rest }) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H44C46.2091 0 48 1.79086 48 4V8C48 30.0914 30.0914 48 8 48H4C1.79086 48 0 46.2091 0 44V4Z"
        fill={color}
      />
      <rect
        x="12"
        y="15"
        width="12"
        height="12"
        rx="3"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M25.5765 18.6396L28.5765 17.237C29.2395 16.927 30 17.4109 30 18.1429V24.0743C30 24.778 29.2926 25.2616 28.637 25.0061L25.637 23.8373C25.2529 23.6877 25 23.3177 25 22.9055V19.5455C25 19.1572 25.2247 18.804 25.5765 18.6396Z"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};
