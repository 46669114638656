import React from 'react';
import autobind from 'class-autobind';
import PropTypes from 'prop-types';

class BridgeBarComponent extends React.Component {
    constructor(props) {
        super(props);
        this.script = '';
        this.bbEl = null;
        this.logoutLink = '';
        this.bridgeBarRef = React.createRef();
        autobind(this);
    }

    componentDidMount() {
        this.script = document.createElement('script');
        this.script.src = 'https://assets.bridge.coxautoservices.com/bridge-bar.js';
        this.script.async = true;
        document.body.appendChild(this.script);

        this.script.addEventListener('load',  () => {
            this.bbEl = this.bridgeBarRef.current;
        
            this.bbEl.addEventListener('initialized', this.intializeBridgeBarListener);
        });

       
    }

    componentWillUnmount() {
        document.body.removeChild(this.script);

        // removing all event listeners
        if(this.bbEl) {
            this.bbEl.removeEventListener('initialized', this.intializeBridgeBarListener);
        }

        if(this.logoutLink) {
            this.logoutLink.removeEventListener('click', this.logOutListener);
        }  
    }

    // event listener when logout link in bridge bar is clicked
    async logOutListener (e) {
        e.preventDefault();

        const { logOutUser } = this.props;
    
        try {
            await logOutUser(false, e.target.href);
        } catch(err) {
            document.location.href = e.target.href;
        }
    }

    // event listener when bridge bar is initialized
    async intializeBridgeBarListener(e) {
        const { sessionId, env } = this.props;
        
        this.bbEl.bb.render({
            solutionGroupCode: 'AT2',
            sessionId,
            env
        });
        
        // waiting for bridge bar to load completely in DOM otherwise logout link query will return null
        await new Promise(resolve => setTimeout(resolve, 1000));

        // assuming bridge bar logout link's class name has not changed while querrying 
        // if query returns null then check if its class name is changed or not
        this.logoutLink = e.target.querySelector('a.bb-logout');
        this.logoutLink.addEventListener('click', this.logOutListener);
    }

    render() {
        return ( <span id="bridge-bar" ref={this.bridgeBarRef} /> );
    }
}

BridgeBarComponent.propTypes = {
    logOutUser: PropTypes.func.isRequired,
    sessionId: PropTypes.string.isRequired,
    env: PropTypes.string.isRequired  
};

export default BridgeBarComponent;

