import React from 'react';
import styles from './Lock.module.scss';
import LockIcon from '../../icons/Lock';
import LockOpenIcon from '../../icons/LockOpen';
import LockUnselectedIcon from '../../icons/LockUnselected';
import Button from '../Button/Button';
import { LockStatus } from '../lib/constants';

export default function Lock({ lockStatus, onClick = () => {} }) {
  if (!lockStatus) {
    return (
      <Button
        text
        class={styles.none}
        disabled
      >
        <LockIcon />
      </Button>
    );
  }
  switch (lockStatus) {
    case LockStatus.LOCKED:
      return (
        <Button
          text
          onClick={onClick}
          class={styles.button}
        >
          <LockIcon color='#CC4331' />
        </Button>
      );
    case LockStatus.UNLOCKED:
      return (
        <Button
          text
          onClick={onClick}
          class={styles.button}
        >
          <LockOpenIcon color='#6D727A' />
        </Button>
      );
    case LockStatus.UNSELECTED:
      return (
        <Button
          text
          onClick={onClick}
          class={styles.button}
        >
          <LockUnselectedIcon color='#00487F' />
        </Button>
      );
    default:
      return (
        <Button
          text
          class={styles.none}
          disabled
        >
          <LockIcon />
        </Button>
      );
  }
}
