import React from 'react';
import './ToggleSwitch.css';

const ToggleSwitch = ({ checked = false, onChange, children }) => {
  return (
    <div
      className="toggle-switch"
      data-testid="toggle-switch"
    >
      <input
        type="checkbox"
        className="toggle-switch-checkbox"
        checked={checked}
        readOnly
      />
      <label
        className="toggle-switch-label"
        htmlFor="toggle-switch-checkbox"
        onClick={onChange}
      >
        <span className="toggle-switch-inner" />
        <span className="toggle-switch-switch" />
      </label>
      <span className="toggle-switch-children">{children}</span>
    </div>
  );
};

export default ToggleSwitch;
