import React from 'react';
import { FormDropdown } from '../../reusableComponents';
import PropsDescription from '../../components/PropsDescription/PropsDescription';
import { LockStatus } from '../../reusableComponents/lib/constants';

export default function FormDropdownContainer() {
  const trimOptions = [
    {
      value: 0,
      label: 'LX',
    },
    {
      value: 1,
      label: 'ES',
    },
    {
      value: 2,
      label: 'LE',
    },
  ];

  const dessertOptions = [
    {
      value: 0,
      label: 'New York Cheesecake',
    },
    {
      value: 1,
      label: 'Tiramisu',
    },
    {
      value: 2,
      label: 'Chocolate Mousse',
    },
  ];
  const FormDropdownProps = [
    {
      name: 'label',
      type: 'string',
      notes: 'Label of dropdown',
    },
    {
      name: 'name',
      type: 'string',
      notes: 'Name of dropdown',
    },
    {
      name: 'multiple',
      type: 'boolean',
      notes: 'Indicates that multiple options can be selected in the list, otherwise only one',
    },
    {
      name: 'size',
      type: 'number',
      notes: 'Limit viewable items to this number',
    },
    {
      name: 'value',
      type: 'string',
      notes: 'Alternative to setting option selected attribute(s). Selected option value',
    },
    {
      name: 'options',
      type: 'Array of object { value: number, label: string }',
      notes:
        'Alternative to option children. JSON string containing the label, value, optional unique key, and selected state of each option',
    },
    {
      name: 'disabled',
      type: 'boolean',
      notes: 'Disable dropdown',
    },
    {
      name: 'onInput',
      type: 'function',
      notes: 'Handle input/change events',
    },
    {
      name: 'required',
      type: 'boolean',
      notes: 'Show asterisk (*) to indicate dropdown is a required field',
    },
    {
      name: 'readOnly',
      type: 'boolean',
      notes: 'Dropdown is read only and cannot be modified',
    },
    {
      name: 'lockStatus',
      type: 'LockStatus | null',
      notes: 'Show lock icon next to the dropdown field',
    },
    {
      name: 'onLockClick',
      type: 'function',
      notes: 'Handle action when lock button is clicked',
    },
    {
      name: 'className',
      type: 'string',
      notes: 'Apply className to dropdown',
    },
    {
      name: 'style',
      type: 'object',
      notes: 'Apply additional styling to dropdown',
    },
  ];
  return (
    <div className="page-component-spacing">
      <h4>Form Dropdown</h4>
      <div>
        <FormDropdown
          label="Trim"
          options={trimOptions}
        />
      </div>
      <div>
        <FormDropdown
          label="Dessert Choices"
          options={dessertOptions}
          value="2"
          required
          lockStatus={LockStatus.LOCKED}
        />
      </div>
      <div className="page-component-spacing">
        <PropsDescription componentProps={FormDropdownProps} />
      </div>
    </div>
  );
}
