import React from 'react';
import './SortableTable.css';
import EditPopup from './EditPopup';

const SortableTable = ({ columns, data, paginationCtrl, emptyText }) => {
  return (
    <div
      className="sortable-table"
      data-testid="sortable-table-container"
    >
      <table className="sortable-table-container">
        <thead className="sortable-table-header-container">
          <tr className="sortable-table-columns">
            {columns.map((column) => {
              return (
                <th
                  key={`header-${column.title}`}
                  role="columnheader"
                  className={`sortable-table-title ${column.className || ''} ${
                    (column.bolded && 'sortable-bolded-column') || ''
                  }`}
                >
                  {column.title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="sortable-table-body">
          {data.map((item, index) => {
            return (
              <tr
                key={`sortable-table-row-${index}`}
                className="sortable-table-columns sortable-table-entry"
              >
                {columns.map((column) => {
                  return (
                    <td
                      key={`data-${column.title}-${index}`}
                      className={`sortable-table-cell ${column.className || ''} ${
                        (column.bolded && 'sortable-bolded-column') || ''
                      }`}
                    >
                      {column.render ? (
                        <>{column.render(item, column)}</>
                      ) : (
                        <>
                          {column.isEditable ? (
                            <EditPopup
                              dataId={column.identifier ? item[column.identifier] : 'no identifier'}
                              label={column.title}
                              value={item[column.dataIndex]}
                              required={true}
                              prefix={column.prefix}
                              onInput={column.onInput}
                              onSave={column.onSave}
                              error={column.errorMsg}
                            >
                              {item[column.dataIndex]}
                            </EditPopup>
                          ) : (
                            <div> {item[column.dataIndex]}</div>
                          )}
                        </>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SortableTable;
