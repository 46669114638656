import React from 'react';

export default function LockUnselected({ color = '#00528A', ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect
        x="4"
        y="4"
        width="16"
        height="16"
        rx="4"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M11.3506 12.9092H7.50195V11.3164H11.3506V12.9092Z"
        fill={color}
      />
      <path
        d="M16.7783 12.9092H12.9297V11.3164H16.7783V12.9092Z"
        fill={color}
      />
    </svg>
  );
}
