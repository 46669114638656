import React, { useState, useRef } from 'react';
import './Tooltip.css';

const Tooltip = ({ children, text, maxwidth = 'none', space = 16, position, style }) => {
  const [visible, setVisible] = useState(false);
  const [defaultStyle, setDefaultStyle] = useState({});
  const [positionRef, setPositionRef] = useState(position);
  const elRef = useRef(null);

  const getDefaultTooltip = (dimensions) => {
    defaultStyle.left = dimensions.left + dimensions.width / 2;
    if (dimensions.top < window.innerHeight / 2) {
      defaultStyle.top = dimensions.top + dimensions.height + space;
      setPositionRef('bottom');
    } else {
      defaultStyle.bottom = window.innerHeight - dimensions.top + space;
      setPositionRef('top');
    }
  };

  const showTooltip = () => {
    const dimensions = elRef.current.getBoundingClientRect();
    const style = { maxWidth: maxwidth };

    if (!positionRef) {
      getDefaultTooltip(dimensions);
    } else {
      switch (positionRef) {
        case 'top':
          style.bottom = window.innerHeight - dimensions.top + space;
          style.left = dimensions.left + dimensions.width / 2;
          break;
        case 'bottom':
          style.top = dimensions.top + dimensions.height + space;
          style.left = dimensions.left + dimensions.width / 2;
          break;
        case 'left':
          style.top = dimensions.top - 10;
          style.left = dimensions.left - 90 + space;
          break;
        case 'right':
          style.top = dimensions.top - 10;
          style.left = dimensions.left + dimensions.width + 60 + space;
          break;
        default:
          getDefaultTooltip(dimensions);
          break;
      }
    }
    setDefaultStyle(style);
    setVisible(true);
  };

  const hideTooltip = () => {
    setVisible(false);
  };

  return (
    <span
      onMouseOver={showTooltip}
      onMouseOut={hideTooltip}
      className={`tooltip-trigger-text ${style?.tooltip || ''}`}
      ref={elRef}
      data-testid="tooltip-underlined"
    >
      {children}

      {visible && (
        <div
          className={`tooltip-body tooltip-${positionRef}`}
          style={defaultStyle}
          data-testid="tooltip-textbox"
        >
          {typeof text === 'function' && React.isValidElement(text()) ? text() : text}
        </div>
      )}
    </span>
  );
};

export default Tooltip;
