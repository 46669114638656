import React from 'react';
import { Table } from 'react-bootstrap';

const PropsDescription = ({ componentProps }) => {
    return (
        <div>
            <h4>Props Description</h4>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                    <th>Prop Name</th>
                    <th>Prop Type</th>
                    <th>Default Prop</th>
                    <th>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        componentProps.map((prop, index) => {
                            return  (
                                <tr key={index}>
                                    <td>{prop.name}</td>
                                    <td>{prop.type}</td>
                                    <td>{prop.defaultProp}</td>
                                    <td>{prop.notes}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        </div>
    );
};

export default PropsDescription;