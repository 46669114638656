import React from 'react';
 
import SideNavigationBar from  '../reusableComponents/SideNavigationBar';
import PropsDescription from '../components/PropsDescription/PropsDescription';

import { sideNavResponse } from '../utils/mock';

const HeaderProps = [
    {
        name: 'sideNavResponse',
        type: 'array',
        defaultProp: ''
    },
    {
        name: 'sideNavBaseUrl',
        type: 'string',
        defaultProp: ''
    },
    {
        name: 'sendAnalytics',
        type: 'function',
        defaulProp: ''
    }
];

const sendAnalytics = (title) => {
    console.log(`Sending side nav link click to ga: ${title}`);
};

const  SideNavigationBarContainer =  () => {
    return (
        <div className="page-component-spacing">
            <h4>Merchandising Side Navigation Component</h4>
            <SideNavigationBar 
                sideNavResponse={sideNavResponse}
                sideNavBaseUrl='https://dealers7.qa.autotrader.com'
                microSiteBaseUrl='https://merchandising-messaging.awsatcdsnp.autotrader.com'
                currentPage='/microsite/limited-warranty'
                sendAnalytics={sendAnalytics}
            />
            <div className="page-component-spacing">
            <PropsDescription componentProps={HeaderProps}/>
            </div>
        </div>
    );
};

export default SideNavigationBarContainer;