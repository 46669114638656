import React from "react";
import "./NavigationDropdown.css";

const NavigationDropdown = ({ children }) => {
  return (
    <>
      <div
        className="nav-dropdown-container"
        data-testid="nav-dropdown-container"
      >
        {children}
      </div>
    </>
  );
};

export default NavigationDropdown;
