import React from 'react';
 
import { Header } from  '../reusableComponents';
import PropsDescription from '../components/PropsDescription/PropsDescription';

import { logOutUser, menuResponse, atcAdminMenuResponse } from '../utils/mock';

const HeaderProps = [
    {
        name: 'menuResponse',
        type: 'object',
        defaultProp: ''
    },
    {
        name: 'dealerAdminHeaderBaseUrl',
        type: 'string',
        defaultProp: ''
    },
    {
        name: 'dsHeaderBaseUrl',
        type: 'string',
        defaultProp: ''
    },
    {
        name: 'logOutUser',
        type: 'function',
        defaultProp: ''
    },
    {
        name: 'loggingOut',
        type: 'boolean',
        defaultProp: 'false'
    },
    {
        name: 'env',
        type: 'string',
        defaultProp: ''
    },
    {
        name: 'sessionId',
        type: 'string',
        defaultProp: ''
    },
    {
        name: 'sendAnalytics',
        type: 'object',
        defaultProp: ''
    }
];

const sendAnalytics = {
    sendNavClickEvent: (title) => console.log(`Sending header nav link click to ga: ${title}`),
    sendHamburgerIconClickEvent: () => console.log(`Sending hamburger icon click to ga`),
    sendContactUsClickEvent: () => console.log(`Sending contact us click to ga`),
    sendManageAccountClickEvent: () => console.log(`Sending manage account click to ga`),
    sendAgentHomeClickEvent: () => console.log(`Sending agent home click to ga`),
    sendDashboardClickEvent: () => console.log(`Sending dashboard click to ga`),
    sendLogOutEvent: () => console.log(`Sending logout event to ga`),
    sendAdminLogOutEvent: () => console.log(`Sending admin logout event to ga`),
};

const  HeaderConatiner =  () => {
    return (
      <div>
        <div className="page-component-spacing">
          <h4>Dealer/Dealer Admin Header Component (with Bridge Bar)</h4>
          <Header
            menuResponse={menuResponse}
            navHeaderLinkBaseUrl="https://dealers8.qa.autotrader.com"
            logOutUser={logOutUser}
            env="qa"
            sessionId="1234"
            sendAnalytics={sendAnalytics}
          />
        </div>
        <div className="page-component-spacing">
          <h4>ATC Admin Header Component</h4>
          <Header
            menuResponse={atcAdminMenuResponse}
            navHeaderLinkBaseUrl="https://dealers7.qa.autotrader.com"
            logOutUser={logOutUser}
            env="qa"
            sessionId="1234"
            sendAnalytics={sendAnalytics}
          />
        </div>
        <div className="page-component-spacing">
          <PropsDescription componentProps={HeaderProps} />
        </div>
      </div>
    );
};

export default HeaderConatiner;