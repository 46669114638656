import React from 'react';
 
import { Footer } from  '../reusableComponents';
import PropsDescription from '../components/PropsDescription/PropsDescription';

import { menuResponse } from '../utils/mock';

const FooterProps = [
    {
        name: 'menuResponse',
        type: 'object',
        defaultProp: ''
    },
    {
        name: 'dealerAdminHeaderBaseUrl',
        type: 'string',
        defaultProp: ''
    },
    {
        name: 'dsHeaderBaseUrl',
        type: 'string',
        defaultProp: ''
    },
    {
        name: 'sendAnalytics',
        type: 'function',
        defaultProp: ''
    },
];

const  FooterContainer =  () => {
    const sendAnalytics = (title) => {
        console.log(`Sending footer link click to ga: ${title}`);
    };

    return (
        <div className="page-component-spacing ">
            <h4>Footer Component</h4>
            <Footer 
                menuResponse={menuResponse}
                footerLinkBaseUrl='https://dealers7.qa.autotrader.com'
                sendAnalytics={sendAnalytics}

            />
            <div className="page-component-spacing">
                <PropsDescription componentProps={FooterProps}/>
            </div>
        </div>
    );
};

export default FooterContainer;