import React, { useEffect, useState, useCallback } from 'react';
import s3ImagesMock from "./s3ImageMock.json";
import s3ImagesGridMock from "./s3ImageGridMock.json";
import MediaUpload from "../../reusableComponents/MediaUpload/MediaUpload";
import PropsDescription from '../../components/PropsDescription/PropsDescription';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import "./MediaUploadContainer.css";

const MediaUploadContainer  =  () => {
    const MAX_PHOTO_LENGTH = 20;

    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [showReorderSave, setShowReorderSave] = useState(false);

    const MediaUploadProps = [
        {
            name: 'images',
            type: 'array',
            defaultProp: ''
        },
        {
            name: 'listType',
            type: 'string',
            defaultProp: 'list'
        },
        {
            name: 'onUpload',
            type: 'function',
            defaultProp: ''
        },
        {
            name: 'onUploadQueueComplete',
            type: 'function',
            defaultProp: '',
            notes: 'Used for multi file uploads'
        },
        {
            name: 'onDeleteImage',
            type: 'function',
            defaultProp: '',
            notes: 'Pass in image object for grid, image asset id for list'
        },
        {
            name: 'onReorderImageUp',
            type: 'function',
            defaultProp: '',
            notes: 'Required for List type uploader'
        },
        {
            name: 'onReorderImageDown',
            type: 'function',
            defaultProp: '',
            notes: 'Required for List type uploader'
        },
        {
          name: "onReorderSave",
          type: "function",
          defaultProp: "",
        },
        {
          name: "showReorderSave",
          type: "boolean",
          defaultProp: "",
          notes: 'Required for Grid type uploader'
        },
        {
            name: 'onReorder',
            type: 'function',
            defaultProp: '',
            notes: 'Required for Grid type uploader'
        },
        {
            name: 'getLargerUrl',
            type: 'function',
            defaultProp: '',
            notes: 'Required for Grid type uploader'
        },
        {
            name: 'allowMultiFile',
            type: 'boolean',
            defaultProp: 'false'
        },
        {
            name: 'maxPhotoLength',
            type: 'number',
            defaultProp: ''
        },
        {
            name: 'formats',
            type: 'array',
            defaultProp: '[".jpeg", ".jpg", ".gif", ".png"]'
        },
        {
            name: 'maxSize',
            type: 'number',
            defaultProp: '5200000'
        },
        {
            name: 'loading',
            type: 'boolean',
            defaultProp: 'false'
        },
        {
            name: 'sendAnalytics',
            type: 'object',
            defaultProp: ''
        },
    ];

    const onUploadList = async (file) => {
      const mockItem = {
        id: images.length + 1,
        publicUrl: "/vehicleImageNew.png",
        mediaType: "image/jpeg",
        catalogId: "e5f5aa52-149c-4693-83b5-a70b2fae665c",
        distributionName: "dealership",
        createdOn: "2022-08-15T20:09:42.621+00:00",
        lastUpdatedOn: "2022-08-15T20:09:42.621+00:00",
        fileProperties: {
          md5Hash: "3ac50767e3a6384b22151dd709b06172",
          fileSize: 351496,
          originalFileName: file.name,
        },
        imageProperties: {
          imageWidth: "1600",
          imageHeight: "1067",
        },
      };
      setLoading(true);
      return await new Promise((resolve) => {
        setTimeout(() => {
          setImages((images) => [...images, mockItem]);
          setLoading(false);
          resolve();
        }, 2000);
      });
    };

    const onUploadGrid = (file) => {
      const mockItem = {
        id: images.length + 1,
        imageUrl: "/car/vehicleImageNew.jpeg",
        order: images.length + 1,
      };

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if(file.name === "errored.png") {
            reject(new Error('fail'));
            return;
          }
          setImages((images) => [...images, mockItem]);
          resolve();
        }, 2000);
      });
    }

    const onUploadQueueComplete = useCallback(() => {
      return new Promise((resolve) => {
        console.log("done!");
        resolve();
      });

      // return new Promise((resolve, reject) => {
      //   reject('err');
      // });
    }, []);

    const onDeleteImage = (image) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          setImages(images.filter((i) => i.id !== ( checked ? image.id : image)));
          setLoading(false);
          resolve();
        }, 2000);
      });
    };

    const onReorderImageUp = async (index) => {
        setLoading(true);
        return await new Promise((resolve) => {
            setTimeout(() => {
                reorder(index-1, index);
                setLoading(false);
                resolve();
            }, 2000);
        });
    }

    const onReorderImageDown = async (index) => {
        setLoading(true);
        return await new Promise((resolve) => {
            setTimeout(() => {
                reorder(index+1, index)
                setLoading(false);
                resolve();
            }, 2000);
            
        });
    }

    const reorder = (swap, index) => {
        let mod = [];
        for(let i=0; i<images.length; i++){
            if(i===swap){
                mod.push(images[index]);
            }
            else if(i===index){
                mod.push(images[swap]);
            }
            else{
                mod.push(images[i]);
            }
        }
        setImages(mod);
    }

    const onReorderSave = async () => {
      return await new Promise((resolve) => {
        setTimeout(() => {
          setShowReorderSave(false);
          resolve();
        }, 2000);
        
    });
    }

    const onReorder = (newOrder) => {
      setShowReorderSave(true);
      console.log("New ordered set: ", newOrder);
    };
  
    const getLargeUrl = (image) => {
      return image.imageUrl;
    }

    const sendImageListAnalytics = {
      sendCancelDeleteModalEvent: () => console.log("Sending cancel delete to ga"),
      sendOpenDeleteModalEvent: () => console.log("Sending open delete modal to ga"),
      sendDropzoneDropEvent: () => console.log("Sending dropzone drop event to ga"),
      sendDropzoneClickEvent: () => console.log("Sending dropzone click event to ga"),
    };

    const sendImageGridAnalytics = {
      sendOpenSortableDeleteModalEvent: () => console.log("Sending open delete modal to ga"),
      sendCancelSortableDeleteModalEvent: () => console.log("Sending close delete modal to ga"),
      sendCancelSortableImageModalEvent: () => console.log("Sending close image modal to ga")
    };

    const handleCheckboxChange = (checked) => {
      setChecked(checked);
    };


    // useEffect(() => {
    //     setLoading(true);
    //     setTimeout(() => {
    //         setImages(s3ImagesMock.items);
    //         setLoading(false);
    //     }, 2000);
    // }, []);

    useEffect(() => {
        if(checked) {
            setImages(s3ImagesGridMock.items);
        } else {
            setImages(s3ImagesMock.items);
        }
    }, [checked]);


    return (
      <div className="page-component-spacing">
        <h4>Media Upload Component</h4>
        <ButtonGroup variant="secondary">
          <Button
            active={checked}
            onClick={() => handleCheckboxChange(false)}
          >
            List
          </Button>
          <Button
            active={!checked}
            onClick={() => handleCheckboxChange(true)}
          >
            Grid
          </Button>
        </ButtonGroup>
        <div className="upload-info-block">
          {`The Max Photo Limit is set to ${MAX_PHOTO_LENGTH}`}
        </div>
        <MediaUpload
          images={images}
          listType={checked ? "grid" : "list"}
          allowMultiFile={checked ? true : false}
          onUpload={checked ? onUploadGrid : onUploadList}
          onUploadQueueComplete={onUploadQueueComplete}
          onDeleteImage={onDeleteImage}
          onReorderImageUp={onReorderImageUp}
          onReorderImageDown={onReorderImageDown}
          onReorderSave={onReorderSave}
          showReorderSave={showReorderSave}
          onReorder={onReorder}
          getLargeUrl={getLargeUrl}
          columns={5}
          maxPhotoLength={MAX_PHOTO_LENGTH}
          formats={checked ? [".jpeg", ".jpg", ".gif", ".png", ".zip"] : [".jpeg", ".jpg", ".gif", ".png"]}
          maxSize={5200000}
          loading={loading}
          sendAnalytics={checked ? sendImageGridAnalytics : sendImageListAnalytics}
        />
        <p>
          Note: Must include nVision stylesheet for icons to display correctly
        </p>
        <pre className="upload-code-block">
          &lt;link
          href="https://assets.nvision.coxautoinc.com/nvision-icon-font/nvision-icon-font.css"
          type="text/css" rel="stylesheet" media="screen, projector"/&gt;
        </pre>
        <div className="page-component-spacing">
          <PropsDescription componentProps={MediaUploadProps} />
        </div>
      </div>
    );
}

export default MediaUploadContainer;