import React, { forwardRef, useState } from "react";
import "./PlainImage.css";

export const PlainImage = forwardRef(
  ({ image, url, faded, style, expandImage, onDelete, ...props }, ref) => {
    const [hasError, setHasError] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    return (
      <div
        className="sortable-image-container"
        onMouseOver={() => {
          if (!isHovered) setIsHovered(true);
        }}
        onMouseLeave={() => {
          if (isHovered) setIsHovered(false);
        }}
        data-testid="sortable-image"
      >
        <img
          ref={ref}
          style={style}
          src={url}
          alt="vehicle"
          className={`sortable-image-item ${hasError ? "sortable-image-invalid" : ""}`}
          onError={() => setHasError(true)}
          data-testid="sortable-img"
          {...props}
        />
        <div className={`${isHovered ? "sortable-image-overlay" : "no-overlay"}`}>
          <div className="sortable-drag-icon" data-testid="sortable-image-drag">
            <i className={`icon icon-drag-31`} />
          </div>
        </div>
        <div className={`${isHovered ? "icon-overlay" : "no-overlay"}`} data-testid="sortable-icons-overlay">
          <div
            className="sortable-zoom-icon"
            data-testid="sortable-image-zoom"
            onClick={() => {
              expandImage(image);
            }}
          >
            <i className={`icon icon-search-zoom-in-1`} />
          </div>
          <div
            className="sortable-delete-icon"
            data-testid="sortable-image-delete"
            onClick={() => {
              onDelete(image)
            }}
          >
            <i className={`icon icon-bin`} />
          </div>
        </div>
      </div>
    );
  }
);
