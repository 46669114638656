import React from 'react';
import './Spinner.css';

const Spinner = ({ height = '60px', width = '60px', radius = '14.5' }) => {
  return (
    <svg
      className="spinner-svg"
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          id="spinner-gradient"
          gradientTransform="rotate(90)"
        >
          <stop
            offset="2%"
            stopColor="transparent"
          />
          <stop
            offset="95%"
            stopColor="#00528A"
          />
        </linearGradient>
      </defs>
      <circle
        cx="50%"
        cy="50%"
        r={radius}
        stroke="url(#spinner-gradient)"
        strokeWidth="3px"
        fill="transparent"
      />
    </svg>
  );
};

export default Spinner;
