import React from 'react';
import styles from './FormDropdown.module.scss';
import Lock from '../Lock/Lock';

export default function FormDropdown({
  label,
  name,
  disabled,
  multiple,
  size,
  value,
  options,
  onInput,
  required,
  readOnly,
  lockStatus,
  onLockClick,
  className,
  style,
  ...rest
}) {
  return (
    <div className={`${styles.dropdownContainer} ${style?.dropdownContainer || ''}`}>
      {label && (
        <div className={`${styles.label} ${style?.label || ''}`}>
          {label} {required && <span className={styles.required}>*</span>}
        </div>
      )}
      <div className={`${styles.value} ${style?.value || ''}`}>
        {!readOnly && (
          <nsemble-select
            name={name}
            value={value}
            disabled={disabled}
            multiple={multiple}
            size={size}
            options={JSON.stringify(options)}
            onInput={onInput}
            class={className}
            {...rest}
          />
        )}
        {readOnly && <div>{value}</div>}
        <Lock
          lockStatus={lockStatus}
          onClick={onLockClick}
        />
      </div>
    </div>
  );
}
