import React, { useState } from "react";
import PropTypes from 'prop-types';
import Spinner from "../Spinner/Spinner";

import './ThumbnailItem.css';

const ThumbnailItem = ({mediaAsset, first, last, onDelete, onReorderUp, onReorderDown, onDeleting, loading}) => {
    const [showSpinner, setShowSpinner] = useState(false);
    const { publicUrl } = mediaAsset;

    const onUpClick = async () => {
        setShowSpinner(true);
        await onReorderUp();
        setShowSpinner(false);
    };

    const onDownClick = async () => {
        setShowSpinner(true);
        await onReorderDown();
        setShowSpinner(false);
    };

    const onDeleteClick = async () => {
        await onDelete();
    }

    return (
        <li className="thumbnail-item">
            <div className="button-list">
                {!first && <button className="button" data-testid="thumbnail-up" onClick={onUpClick} disabled={loading}><i className="icon icon-16 icon-minimal-up arrow-icon"/></button>}
                {!last && <button className="button" data-testid="thumbnail-down" onClick={onDownClick} disabled={loading}><i className="icon icon-16 icon-minimal-down arrow-icon"/></button>}
                <button className="button background-delete" data-testid="thumbnail-delete" onClick={onDeleteClick} disabled={loading}><i className="icon icon-16 icon-bin delete-icon"/></button>
            </div>
            <div className="thumbnail-image-container">
                <img className={`thumbnail-image ${showSpinner ? 'thumbnail-image-mask' : ''}`} src={publicUrl} alt="thumbnail"></img>
                { (showSpinner || onDeleting) && <div className="thumbnail-spinner"><Spinner height="80px" width="80px" radius="25"/></div> }
            </div>
        </li>
    )
};

ThumbnailItem.propTypes = {
    mediaAsset: PropTypes.object,
    first: PropTypes.bool,
    last: PropTypes.bool,
    onDelete: PropTypes.func,
    onReorderUp: PropTypes.func,
    onReorderDown: PropTypes.func,
    onDeleting: PropTypes.bool,
    loading: PropTypes.bool
}

export default ThumbnailItem;