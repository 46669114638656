import React from 'react';

export default function Video({ color = '#00528A', ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect
        x="3"
        y="6"
        width="12"
        height="12"
        rx="3"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M16.5765 9.63957L19.5765 8.23697C20.2395 7.92698 21 8.41093 21 9.14286V15.0743C21 15.778 20.2926 16.2616 19.637 16.0061L16.637 14.8373C16.2529 14.6877 16 14.3177 16 13.9055V10.5455C16 10.1572 16.2247 9.80402 16.5765 9.63957Z"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};
