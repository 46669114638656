import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Alerts.css';

const Alerts = ({ children, type = 'message', dismissible = false }) => {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <div>
      {isVisible && (
        <div
          className={`alerts-container alerts--${type} ${dismissible ? 'alerts-dismissible' : ''}`}
          data-testid="alerts-container"
        >
          <div className="alerts-body">{children}</div>
          {dismissible && (
            <button
              className={`alerts-close alerts-close--${type}`}
              onClick={() => setIsVisible(false)}
              data-testid="alerts-close"
            >
              <i className="icon icon-16 icon-e-remove-1" />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

Alerts.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  dismissible: PropTypes.bool,
};

export default Alerts;
