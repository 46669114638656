import React, { forwardRef } from 'react';
import { useNavigation } from './NavigationProvider';
import { NativeLink } from './NativeLink';

/** implementation agnostic Link component, basic on the standard `a` tag */
const UniversalLink = forwardRef(( props, ref) => {
  const nav = useNavigation();
  const ActualLink = nav.Link || NativeLink;

  if (props?.native || props?.external) {
    return <NativeLink {...props} ref={ref} />;
  }

  return <ActualLink {...props} ref={ref} />;
});

export default UniversalLink;