import { useEffect, useState, useRef } from 'react';
import PropsDescription from '../../components/PropsDescription/PropsDescription';
import { SortableTable, SortBySelect } from '../../reusableComponents';
import tableItemsMock from './tableItemsMock.json';

const SortableTableAndSelectProps = [
  {
    name: 'columns (SortableTable)',
    type: 'Array of column objects',
    notes: 'Defines the column configurations',
  },
  {
    name: 'data (SortableTable)',
    type: 'string',
    notes: 'Array of data objects',
  },
  {
    name: 'sortByOptions (SortBySelect)',
    type: 'array of objects with "display" and "value" properties',
    notes: 'Displayed value and value of the select options',
  },
  {
    name: 'value (SortBySelect)',
    type: 'string',
    notes: 'Initial value of select',
  },
  {
    name: 'onSortByChange (SortBySelect)',
    type: 'function',
    notes: 'Called when select value changes',
  },
  {
    name: 'onSortOrderChange (SortBySelect)',
    type: 'function',
    notes: 'Called when desc/asc order is toggled',
  },
];

const compare = (property, order) => {
  var sortOrder = 1;
  if (order !== 'desc') {
    sortOrder = -1;
  }
  return (a, b) => {
    var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

const validateInput = (input) => {
  if (input.length) return true;
  return false;
};

const sortByOptions = [
  { display: 'Name', value: 'name' },
  { display: 'Price', value: 'price' },
  { display: 'Calories', value: 'calories' },
];

const CustomCell = (item, column) => {
  return (
    <div>
      <h3 style={{ color: 'red' }}>My Custom Component: {item.name}</h3>
    </div>
  );
};

const TableContainer = () => {
  const [data, setData] = useState(tableItemsMock.items);
  const [sortBy, setSortBy] = useState(sortByOptions[0].value);
  const [priceError, setPriceError] = useState(false);

  // Create a ref to point to current value of data so it can be used in onPriceSave
  const currentDataRef = useRef(data);

  const onPriceSave = (id, input) => {
    const dataInd = currentDataRef.current.findIndex((item) => item.id === id);
    let newData = [...currentDataRef.current];
    newData[dataInd].price = input;
    currentDataRef.current = newData;
    setData(newData);
  };

  const onPriceInput = (e) => {
    setPriceError(false);
    if (!validateInput(e.target.value)) setPriceError(true);
  };

  const initialColumns = [
    {
      dataIndex: 'name',
      title: 'Name',
    },
    {
      dataIndex: 'price',
      title: 'Price',
      isEditable: true,
      prefix: '$',
      onInput: onPriceInput,
      onSave: onPriceSave,
      errorMsg: '',
      identifier: 'id',
    },
    {
      dataIndex: 'calories',
      title: 'Calories',
    },
    {
      title: 'Custom column',
      render: CustomCell,
    },
  ];
  const [columns, setColumns] = useState(initialColumns);

  // Setting an error message when price input validation fails
  useEffect(() => {
    const priceColInd = columns.findIndex((col) => col.dataIndex === 'price');
    let newCols = [...columns];
    if (priceError) {
      newCols[priceColInd].errorMsg = 'Please enter a valid price.';
    } else {
      newCols[priceColInd].errorMsg = '';
    }

    setColumns(newCols);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceError]);

  // Modify column array to bold the column being sorted by
  const boldColumn = (sortBy) => {
    const colInd = columns.findIndex((col) => col.dataIndex === sortBy);
    const newColumns = [...columns];
    for (let i = 0; i < newColumns.length; i++) {
      if (i === colInd) {
        newColumns[i].bolded = true;
      } else if (newColumns[i].hasOwnProperty('bolded')) {
        delete newColumns[i].bolded;
      }
    }
    return newColumns;
  };

  const onSortOrderChange = (newOrder) => {
    //console.log(`Sorting by ${sortBy} in ${newOrder} order`);

    const newData = [...data];
    newData.sort(compare(sortBy, newOrder));
    currentDataRef.current = newData;
    setData(newData);
    setColumns(boldColumn(sortBy));
  };

  const onSortByChange = (e, toggleOrder) => {
    const newSortBy = e.target.value;
    const newData = [...data];
    newData.sort(compare(newSortBy, toggleOrder));

    currentDataRef.current = newData;
    setData(newData);
    setSortBy(newSortBy);
    setColumns(boldColumn(newSortBy));
  };

  return (
    <div className="page-component-spacing ">
      <h4>Sortable Table Component And Sort By Select</h4>
      <SortBySelect
        sortByOptions={sortByOptions}
        value={sortBy}
        onSortByChange={onSortByChange}
        onSortOrderChange={onSortOrderChange}
      ></SortBySelect>
      <SortableTable
        columns={columns}
        data={data}
      ></SortableTable>
      <div className="page-component-spacing">
        <PropsDescription componentProps={SortableTableAndSelectProps} />
      </div>
    </div>
  );
};

export default TableContainer;
