import React from 'react';

export default function FloppyDisk({ color = '#00528A', ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M21 8.81971V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3H14.833C15.3334 3 15.8156 3.18757 16.1845 3.52569L20.3514 7.3454C20.7647 7.72422 21 8.25909 21 8.81971Z"
        stroke={color}
        strokeWidth="2"
      />
      <circle
        cx="12"
        cy="14"
        r="3"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M7 3V6C7 6.55228 7.44772 7 8 7H13C13.5523 7 14 6.55228 14 6V3"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
}
