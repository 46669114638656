import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Card from '../Card';
import FailsafeImage from '../FailSafeImage';
import Alerts from '../Alerts';
import Button from '../Button';
import { default as CameraIcon } from '../../icons/Camera';
import { default as QuestionIcon } from '../../icons/Question';
import { default as UploadIcon } from '../../icons/Upload';
import { default as VideoIcon } from '../../icons/Video';
import { default as VideoBigTagIcon } from '../../icons/VideoBigTag';
import { default as VideoTagIcon } from '../../icons/VideoTag';
import './MediaCarousel.css';

const BREAK_POINT_MOBILE = 430;
const isMobile = () => {
  return window.innerWidth <= BREAK_POINT_MOBILE || window.innerHeight <= BREAK_POINT_MOBILE;
};

const crunchURL = (url) => {
  if (url) {
    return url.replace(/[^a-zA-Z0-9]/g, '');
  }
  return 'abc';
};

const changeToSsl = (url) => {
  return url.replace('http://', 'https://');
};

const getVideoThumbFromXML = (url, large = false) => {
  //TODO: Are we getting our videos from xml?
  if (url) {
    if (url.slice(-3)?.toLowerCase() === 'xml') {
      const part = large ? '_largethumb.png' : '_mediumthumb4x3.png';
      return changeToSsl(`${url.substring(0, url.length - 4)}${part}`);
    }
  } else {
    return '';
  }
  return url;
};

const getVideoFromXML = (url, isHD = true) => {
  //TODO: Are we getting our videos from xml?
  if (url?.slice(-3)?.toLowerCase() === 'xml') {
    return changeToSsl(`${url.substring(0, url.length - 4)}${isHD ? '_high.mp4' : '_low.mp4'}`);
  }
  return url;
};

const getVideoCaptionUrlFromXML = (url) => {
  //TODO: Are we getting our videos from xml?
  if (url.slice(-3)?.toLowerCase() === 'xml') {
    return changeToSsl(`${url.substring(0, url.length - 4)}_transcript.srt`);
  }
  return url;
};

const validVideos = (videos) => {
  if (videos) {
    return videos.length;
  }
  return 0;
};

const makeVTTfromSRT = (srtStr) => {
  const lines = srtStr.split('\n');
  const text = lines.reduce((str, line) => {
    if (/(\d\d):(\d\d):(\d\d),(\d\d\d)/.test(line)) {
      return `${str}${line.replace(/,/g, '.')}\n`;
    }
    return `${str}${line}\n`;
  }, 'WEBVTT \n');

  return new Blob([text], { type: 'text/plain' });
};

const loadCaptions = async (id, url, videoCaptions, setVideoCaptions, setCaptionsButton) => {
  const response = await fetch(url);
  if (response && response.ok) {
    const payload = await response.text();

    setVideoCaptions({ ...videoCaptions, [id]: makeVTTfromSRT(payload) });
    setCaptionsButton(true);
  } else {
    setCaptionsButton(false);
  }
};

const createNav = (assets, currentAssetIdx, setCurrentAssetIdx, imgLength, vidLength) => {
  return (
    <>
      <div className="icon-line">
        <div className="asset-group">
          <span>Images</span>
          <CameraIcon />
          <div>{imgLength}</div>
        </div>
      </div>
      <ul className="images-nav">
        {Boolean(assets) &&
          assets.map(({ isVideo, url, thumbnailUrl }, idx) => {
            return (
              !isVideo && (
                <li
                  key={crunchURL(url)}
                  className={idx === currentAssetIdx ? 'active' : ''}
                >
                  <Button
                    text
                    onClick={() => {
                      setCurrentAssetIdx(idx);
                    }}
                    id={crunchURL(url)}
                  >
                    <div className="thumb-container">
                      <FailsafeImage src={thumbnailUrl} />
                    </div>
                  </Button>
                </li>
              )
            );
          })}
      </ul>
      <div className="icon-line">
        <div className="asset-group">
          <span>Videos</span>
          <VideoIcon />
          <div>{vidLength}</div>
        </div>
      </div>
      <ul className="videos-nav">
        {Boolean(assets) &&
          assets.map(({ isVideo, url }, idx) => {
            return (
              isVideo && (
                <li
                  key={crunchURL(url)}
                  className={idx === currentAssetIdx ? 'active' : ''}
                >
                  <Button
                    text
                    onClick={() => setCurrentAssetIdx(idx)}
                    id={crunchURL(url)}
                  >
                    <div className="thumb-container">
                      <VideoTagIcon className="icon-overlay" />
                      <FailsafeImage src={getVideoThumbFromXML(url)} />
                    </div>
                  </Button>
                </li>
              )
            );
          })}
      </ul>
    </>
  );
};

const showCurrentAsset = (asset, HDstatus, videoRef) => {
  if (!asset) {
    return null;
  }
  const { isVideo, url, id } = asset;
  if (isVideo) {
    return (
      <div className="thumb-container">
        <VideoBigTagIcon className="icon-overlay" />
        <video
          ref={videoRef}
          controls
          disablePictureInPicture
          controlsList="nodownload nocaptions"
          poster={getVideoThumbFromXML(url, true)}
          src={getVideoFromXML(url, HDstatus[id])}
          data-testid={`current-${crunchURL(url)}`}
        />
      </div>
    );
  }
  return (
    <FailsafeImage
      src={url}
      alt="selected"
    />
  );
};

const showMoreVideoControls = (
  asset,
  HDstatus,
  setHDstatus,
  videoRef,
  captionsButton,
  captionsStatus,
  setCaptionsStatus
) => {
  if (asset && asset.isVideo) {
    const { id, url } = asset;
    let capButton = null;
    if (captionsButton) {
      capButton = (
        <Button
          className="hd-vdp-media-cc-button-overlay"
          primary={captionsStatus ? true : undefined}
          onClick={() => {
            const show = !captionsStatus;
            setCaptionsStatus(show);
            if (videoRef.current) {
              const videoEl = videoRef.current;
              videoEl.textTracks[0].mode = show ? 'showing' : 'disabled';
            }
          }}
        >
          CC
        </Button>
      );
    }
    return (
      <>
        {capButton}
        <Button
          className="hd-vdp-media-button-overlay"
          primary={HDstatus[id] ? true : undefined}
          onClick={() => {
            const hd = !HDstatus[id];
            setHDstatus({ ...HDstatus, [id]: hd });
            if (videoRef.current) {
              const videoEl = videoRef.current;
              videoEl.src = getVideoFromXML(url, hd);
            }
          }}
        >
          HD
        </Button>
      </>
    );
  }
  return null;
};

const MediaCarousel = ({ title, images, videos, linkName, onLinkClick, infoMsg }) => {
  const [assets, setAssets] = useState([]);
  const [currentAssetIdx, setCurrentAssetIdx] = useState(0);
  const [HDstatus, setHDstatus] = useState({});
  const [videoCaptions, setVideoCaptions] = useState({});
  const [captionsButton, setCaptionsButton] = useState(false);
  const [captionsStatus, setCaptionsStatus] = useState(false);

  const videoRef = useRef();
  const nVideos = validVideos(videos);

  useEffect(() => {
    const list = [];
    images?.forEach(({ url, thumbnailUrl }) => {
      list.push({ isVideo: false, url, thumbnailUrl });
    });
    videos?.forEach(({ videoId, url }) => {
      const id = crunchURL(url || videoId);
      list.push({
        isVideo: true,
        url: url || '',
        videoId,
        id,
      });
      setHDstatus({ ...HDstatus, [id]: !isMobile() });
      if (url) {
        loadCaptions(id, getVideoCaptionUrlFromXML(url), videoCaptions, setVideoCaptions, setCaptionsButton);
      }
    });
    setAssets(list);
  }, [images, videos]); // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      videoRef.current &&
      assets.length &&
      assets[currentAssetIdx]?.isVideo &&
      videoCaptions[assets[currentAssetIdx].id]
    ) {
      const vid = videoRef.current;
      if (vid && !vid.textTracks?.length) {
        const VTTFile = videoCaptions[assets[currentAssetIdx].id];
        setCaptionsStatus(false);
        const track = document.createElement('track');
        track.kind = 'captions';
        track.label = 'English';
        track.srclang = 'en';
        track.src = URL.createObjectURL(VTTFile);
        vid.addEventListener('loadedmetadata', () => {
          vid.textTracks[0].mode = 'disabled';
          vid.textTracks.onchange = (e) => {
            setCaptionsStatus(e.target[0].mode === 'showing');
          };
        });
        vid.append(track);
      }
    }
  });

  const currentAsset = assets.length ? assets[currentAssetIdx] : null;

  return (
    <>
      <Card
        title={
          <>
            <span>{title}</span>
          </>
        }
      >
        <div
          className="vehicle-media"
          data-testid="media-carousel"
        >
          <div className="current-asset">
            <div className="hd-buttons-container">
              {showMoreVideoControls(
                currentAsset,
                HDstatus,
                setHDstatus,
                videoRef,
                captionsButton,
                captionsStatus,
                setCaptionsStatus
              )}
            </div>

            {showCurrentAsset(currentAsset, HDstatus, videoRef)}
          </div>

          <div className="nav-area">
            <div>{createNav(assets, currentAssetIdx, setCurrentAssetIdx, images.length, nVideos)}</div>
            {linkName && (
              <div
                className="vehicle-media-upload-link"
                onClick={onLinkClick}
              >
                <UploadIcon />
                <span className="upload-link-text">{linkName}</span>
              </div>
            )}
            {infoMsg && (
              <div className="info-section-container">
                <Alerts type="message">
                  <div className="info-section">
                    <QuestionIcon />
                    <span>{infoMsg}</span>
                  </div>
                </Alerts>
              </div>
            )}
          </div>
        </div>
      </Card>
    </>
  );
};

MediaCarousel.propTypes = {
  title: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.any).isRequired,
  videos: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkName: PropTypes.string,
  onLinkClick: PropTypes.func,
  infoMsg: PropTypes.string,
};

export default MediaCarousel;
