import React from 'react';
import styles from './Divider.module.scss';

export default function Divider({ style, ...rest }) {
  return (
    <hr
      className={`${styles.divider} ${style?.divider || ''}`}
      {...rest}
    />
  );
}
