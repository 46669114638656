import React, { useState, useEffect } from "react";
import SortableImageGrid from "../../reusableComponents/SortableImageGrid";
import PropsDescription from "../../components/PropsDescription/PropsDescription";
import s3ImagesMock from "./s3ImageMock.json";

const SortableImageGridContainer = () => {
  const [showReorderSave, setShowReorderSave] = useState(false);

  const SortableImageGridProps = [
    {
      name: "images",
      type: "array",
      defaultProp: "",
    },
    {
      name: "onReorderSave",
      type: "function",
      defaultProp: "",
    },
    {
      name: "onReorder",
      type: "function",
      defaultProp: "",
    },
    {
      name: "showReorderSave",
      type: "boolean",
      defaultProp: "",
      notes: 'Required for Grid type uploader'
    },
    {
      name: "onDelete",
      type: "function",
      defaultProp: "",
    },
    {
      name: "getLargeUrl",
      type: "function",
      defaultProp: "",
    },
    {
      name: "columns",
      type: "number",
      defaultProp: "5",
    },
    {
      name: "sendAnalytics",
      type: "object",
      defaultProp: "",
    },
  ];

  const onReorder = (newOrder) => {
    setShowReorderSave(true);
    console.log("New ordered set: ", newOrder);
  };

  const onReorderSave = () => {
    setShowReorderSave(false);
    console.log("Saving current order");
  }

  const onDelete = async (imageId) => {
    setImages(images.filter((i) => i.id !== imageId));
    // return await new Promise((resolve) => {
    //     setTimeout(() => {
    //         setImages(images.filter((i) => i.id !== imageId));
    //         setLoading(false);
    //         resolve();
    //     }, 2000)
    // });
  };

  const getLargeUrl = (image) => {
    return image.imageUrl;
  }

  const [images, setImages] = useState([]);

  const sendImageGridAnalytics = {
    sendOpenSortableDeleteModalEvent: () => console.log("Sending open delete modal to ga"),
    sendCancelSortableDeleteModalEvent: () => console.log("Sending close delete modal to ga"),
    sendCancelSortableImageModalEvent: () => console.log("Sending close image modal to ga")
  };

  useEffect(() => {
    setImages(s3ImagesMock.items);
  }, []);

  return (
    <div className="page-component-spacing">
      <SortableImageGrid
        images={images}
        onReorder={onReorder}
        onReorderSave={onReorderSave}
        showReorderSave={showReorderSave}
        onDelete={onDelete}
        getLargeUrl={getLargeUrl}
        columns={5}
        sendAnalytics={sendImageGridAnalytics}
      ></SortableImageGrid>
      <div className="page-component-spacing">
        <PropsDescription componentProps={SortableImageGridProps} />
      </div>
    </div>
  );
};

export default SortableImageGridContainer;
