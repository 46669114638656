import React from "react";
import "./Table.css";

const Table = ({ headers, data, RowComponent }) => {
  return (
    <>
      <table className="table-container" data-testid="table-container">
        <thead>
          <tr>
            {headers.map((header) => {
              return (
                <th
                  key={`col-${header}`}
                  className="table-header"
                >
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return (
              <tr key={`row-${index}`}>
                {RowComponent ? (
                  <RowComponent item={item} />
                ) : (
                  <>
                    {Object.keys(item).map((key) => {
                      return (
                        <td
                          key={`table-cell-${index}-${key}`}
                          className="table-row"
                        >
                          {item[key]}
                        </td>
                      );
                    })}
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default Table;
