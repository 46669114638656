import React from "react";
import { ToggleSwitch } from "../../reusableComponents";
import "./TableRow.css";

const TableRow = ({ item }) => {

  return (
    <>
      <td className="example-table-row example-table-toggle">
        <ToggleSwitch/>
      </td>
      <td className="example-table-row"><img src={item.image_url} alt="table vehicle"/></td>
      <td className="example-table-row">{item.id}</td>
      <td className="example-table-row">{item.year}</td>
      <td className="example-table-row">{item.make}</td>
      <td className="example-table-row">{item.model}</td>
      <td className="example-table-row">{item.trim}</td>
      <td className="example-table-row">{item.stock}</td>
      <td className="example-table-row">{item.price}</td>
      <td className="example-table-row">{item.mileage}</td>
    </>
  );
};

export default TableRow;
