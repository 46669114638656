import React, { useState, useEffect } from 'react';
import './SortBySelect.css';
import { default as SortDownIcon } from '../../icons/SortDown';
import { default as SortUpIcon } from '../../icons/SortUp';
import Select from '../Select/Select';
import Button from '../Button/Button';

const SortBySelect = ({
  id = '',
  name = '',
  selectDisabled = false,
  toggleDisabled = false,
  value = '',
  order = 'desc',
  onSortByChange,
  onSortOrderChange,
  sortByOptions = [], // array of objects with "display" and "value" properties
  className,
}) => {
  const [toggleOrder, setToggleOrder] = useState(order);

  const onToggleClick = () => {
    setToggleOrder((prev) => (prev === 'desc' ? 'asc' : 'desc'));
  };

  useEffect(() => {
    if (toggleOrder && onSortOrderChange) onSortOrderChange(toggleOrder);
  }, [toggleOrder, onSortOrderChange]);

  return (
    <div
      className={`${className || ''} sort-select-container`}
      data-testid="sort-by-select-container"
    >
      <span className="sort-by-label">Sort By</span>
      <div className="sort-select-box">
        <Select
          id={id}
          name={name}
          onInput={(e) => onSortByChange(e, toggleOrder)}
          value={value}
          disabled={selectDisabled || undefined}
        >
          {sortByOptions.map((option) => {
            return (
              <option
                key={`sortby-option-${option.value}`}
                value={option.value}
              >
                {option.display}
              </option>
            );
          })}
        </Select>
      </div>
      <div className="sort-select-toggle">
        <Button
          text
          id="sort-select-toggle"
          class="sortable-select-order-button"
          title={order === 'desc' ? 'Descending' : 'Ascending'}
          onClick={onToggleClick}
          disabled={toggleDisabled ? true : undefined}
        >
          {toggleOrder === 'desc' ? <SortDownIcon /> : <SortUpIcon />}
        </Button>
      </div>
    </div>
  );
};

export default SortBySelect;
