import React from 'react';
import { usePagination, DOTS } from './usePagination';
import PropTypes from 'prop-types';

import './Pagination.css';

const Pagination = ({ onPageChange, totalCount, siblingCount = 1, currentPage, pageSize = 10, className }) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // only render when there are more than one page
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <ul
      className={`pagination-container ${className}`}
      data-testid="pagination-container"
    >
      {/* Left arrow */}
      <li
        className={`pagination-item pagination-arrow ${currentPage === 1 ? 'disabled' : ''}`}
        onClick={onPrevious}
        data-testid="pagination-left-arrow"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <title>Previous</title>
          <g
            className="nc-icon-wrapper"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            fill="#16171a"
            stroke="#16171a"
          >
            <polyline
              fill="none"
              stroke="#16171a"
              strokeMiterlimit="10"
              points="17,2 7,12 17,22 "
              transform="translate(0, 0)"
            />
          </g>
        </svg>
      </li>

      {paginationRange.map((pageNumber, index) => {
        // Render dots as unicode
        if (pageNumber === DOTS) {
          return (
            <li
              key={`page-${index}`}
              className="pagination-item truncation"
              data-testid={`pagination-dots-${index}`}
            >
              <span>&hellip;</span>
            </li>
          );
        }

        // Render our page number buttons
        return (
          <li
            key={`page-${index}`}
            className={`pagination-item ${pageNumber === currentPage ? 'selected' : ''}`}
            onClick={() => onPageChange(pageNumber)}
            data-testid={`pagination-item-${index}`}
          >
            {pageNumber}
          </li>
        );
      })}
      {/* Right arrow */}
      <li
        className={`pagination-item pagination-arrow ${currentPage === lastPage ? 'disabled' : ''}`}
        onClick={onNext}
        data-testid="pagination-right-arrow"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <title>Next</title>
          <g
            className="nc-icon-wrapper"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            fill="#16171a"
            stroke="#16171a"
          >
            <polyline
              fill="none"
              stroke="#16171a"
              strokeMiterlimit="10"
              points="7,2 17,12 7,22 "
              transform="translate(0, 0)"
            />
          </g>
        </svg>
      </li>
    </ul>
  );
};

Pagination.propTypes = {
  onPageChange: PropTypes.func,
  totalCount: PropTypes.number,
  siblingCount: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  className: PropTypes.string,
};

export default Pagination;
