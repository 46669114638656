import React from 'react';
import { Accordion } from '../../reusableComponents';
import { Question } from '../../icons';
import PropsDescription from '../../components/PropsDescription/PropsDescription';

export default function AccordionContainer() {
  const accordionProps = [
    {
      name: 'header',
      type: 'any',
      notes: 'Header of the accordion',
    },
    {
      name: 'text',
      type: 'any',
      notes: 'Text to show of the accordion',
    },
    {
      name: 'style',
      type: 'object: { text: string }',
      notes: 'Apply additional styling to accordion',
    },
  ];
  const text = (
    <div>
      <p>
        <strong>Fair Purchase Price</strong>
      </p>
      <p>
        Updated weekly, the Kelley Blue Book® Fair Purchase Price shows you what others have been paying for this car
        recently. It's based on current market conditions like vehicle availability, local demand, and seasonal buying
        trends
      </p>
      <p>
        <strong>Fair Market Range</strong>
      </p>
      <p>
        The Fair Market Range is Kelley Blue Book's estimate of what you can reasonably expect to pay this week for a
        vehicle configured with your selected options, excluding taxes, title and fees. Each dealer sets and controls
        its own pricing.
      </p>
    </div>
  );
  return (
    <div className="page-component-spacing">
      <h4>Accordion</h4>
      <div>
        <Accordion
          header={<>Fair Purchase Price (FPP)<Question /></>}
          text={text}
        />
      </div>
      <div className="page-component-spacing">
        <PropsDescription componentProps={accordionProps} />
      </div>
    </div>
  );
}
