import React from 'react';
import { LockStatus } from '../../reusableComponents/lib/constants';
import { FormInput } from '../../reusableComponents';
import styles from './FormInputContainer.module.scss';
import PropsDescription from '../../components/PropsDescription/PropsDescription';

export default function FormInputContainer() {
  const FormInputProps = [
    {
      name: 'type',
      type: 'string',
      notes: 'Type of input: text (default) | password | email | number | tel',
    },
    {
      name: 'label',
      type: 'string',
      notes: 'Label of input',
    },
    {
      name: 'name',
      type: 'string',
      notes: 'Name of input',
    },
    {
      name: 'value',
      type: 'any',
      notes: 'Value of input',
    },
    {
      name: 'placeholder',
      type: 'string',
      notes: 'Placeholder of input',
    },
    {
      name: 'error',
      type: 'string',
      notes: 'Error of input',
    },
    {
      name: 'disabled',
      type: 'boolean',
      notes: 'Disable input',
    },
    {
      name: 'variant',
      type: 'boolean',
      notes:
        'Indicates this component will not use the mobile styling (useful when embedding this component inside another component)',
    },
    {
      name: 'autofocus',
      type: 'boolean',
      notes: 'When present the input should automatically get focus when the page loads',
    },
    {
      name: 'onInput',
      type: 'function',
      notes: 'Handle input/change events',
    },
    {
      name: 'prefix',
      type: 'string',
      notes: 'Allows an image or a short string to prefix the input text',
    },
    {
      name: 'postfix',
      type: 'string',
      notes: 'Allows an image or a short string to postfix the input text',
    },
    {
      name: 'required',
      type: 'boolean',
      notes: 'Show asterisk (*) to indicate input is a required field',
    },
    {
      name: 'readOnly',
      type: 'boolean',
      notes: 'Input is read only and cannot be modified',
    },
    {
      name: 'lockStatus',
      type: 'LockStatus | null',
      notes: 'Show lock icon next to the input field',
    },
    {
      name: 'onLockClick',
      type: 'function',
      notes: 'Handle action when lock button is clicked',
    },
    {
      name: 'className',
      type: 'string',
      notes: 'Apply className to input',
    },
    {
      name: 'style',
      type: 'object',
      notes: 'Apply additional styling to input',
    },
  ];
  return (
    <div className="page-component-spacing">
      <h4>Form Input</h4>
      <div>
        <FormInput
          label="Year"
          required
          value={2025}
          readOnly
          lockStatus={LockStatus.LOCKED}
        />
        <FormInput
          label="Make"
          required
          value="Toyota"
          lockStatus={LockStatus.UNLOCKED}
        />
        <FormInput
          label="Model"
          required
          readOnly
          value="Highlander"
        />
        <FormInput
          label="Stock No"
          lockStatus={LockStatus.UNSELECTED}
        />
        <FormInput
          label="Mileage"
          required
          value="59895"
        />
        <FormInput
          label="My Retail Price"
          prefix="$"
          value="60000"
          style={{ label: styles.label }}
        />
        <FormInput
          label="Market Price"
          readOnly
          value="N/A"
          style={{ label: styles.label }}
        />
        <FormInput
          label="My Vehicle Mileage"
          value="5500"
          postfix="mi"
          style={{ label: styles.label }}
        />
        <FormInput
          label="Market Mileage"
          readOnly
          value="N/A"
          style={{ label: styles.label }}
        />
      </div>
      <div className="page-component-spacing">
        <PropsDescription componentProps={FormInputProps} />
      </div>
    </div>
  );
}
