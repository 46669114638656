import React from "react";
import "./NavigationDropdownItem.css";

const NavigationDropdownItem = ({ children }) => {
  return (
    <>
      <div
        className="nav-dropdown-item-container"
        data-testid="nav-dropdown-item-container"
      >
        {children}
      </div>
    </>
  );
};

export default NavigationDropdownItem;
