import { useState, useEffect, useCallback } from 'react';

/**
 * Custom hook for a file processing queue
 * @param {Function} processFile Callback function for processing each file.
 * The callback function can return a promise.
 * @returns An object of the following properties
 * files: An array of files currently in the queue
 * isProcessing: A boolean - true if a file is currently being processed
 * done: A boolean - true when all the files have been processed
 * totalFileCount: The number of total files that have been added to the queue
 * addFile: Callback function for adding a file to the queue
 * erroredFile: A file that has errored during processing
 * - gets reset to null once the next file in the queue starts processing
 */

const useFileQueue = (processFile) => {
  const [queue, setQueue] = useState({
    isProcessing: false,
    files: [],
    done: false,
    totalFileCount: 0,
    erroredFile: null,
  });

  useEffect(() => {
    if (queue.files.length === 0) return;
    if (queue.isProcessing) return;

    const file = queue.files[0];

    setQueue((prev) => ({
      isProcessing: true,
      files: prev.files,
      done: prev.done,
      totalFileCount: prev.totalFileCount,
      erroredFile: null,
    }));

    let hasError = false;

    Promise.resolve(processFile(file))
      .catch((err) => {
        hasError = true;
      })
      .finally(() => {
        setQueue((prev) => ({
          ...queue,
          isProcessing: false,
          files: prev.files.slice(1),
          done: prev.files.length === 1 ? true : false,
          totalFileCount: prev.files.length === 1 ? 0 : prev.totalFileCount,
          erroredFile: hasError ? file : null,
        }));
      });
  }, [queue, processFile]);

  return {
    files: queue.files,
    isProcessing: queue.isProcessing,
    done: queue.done,
    totalFileCount: queue.totalFileCount,
    addFile: useCallback((file) => {
      setQueue((prev) => ({
        isProcessing: prev.isProcessing,
        files: [...prev.files, file],
        done: false,
        totalFileCount: prev.totalFileCount + 1,
        erroredFile: null,
      }));
    }, []),
    erroredFile: queue.erroredFile,
  };
};

export default useFileQueue;
