import React, { useState, useEffect } from "react";
import Pagination from "../../reusableComponents/Pagination/Pagination";
import Table from "../../reusableComponents/Table/Table";
import TableRow from "./TableRow";
import PropsDescription from "../../components/PropsDescription/PropsDescription";
import "./PaginationContainer.css";
import {
  tableMock1,
  tableMock2,
  tableMock3,
  tableMock4,
  tableMock5,
  tableMock6,
  tableMock7,
  tableMock8,
  tableMock9,
  tableMock10
} from "./mocks/index";

const PaginationContainer = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTableData, setCurrentTableData] = useState({
    totalCount: 0,
    items: [],
  });
  const PageSize = 10;
  const headers = [
    "Include",
    "",
    "Car Id",
    "Year",
    "Make",
    "Model",
    "Trim",
    "Stock #",
    "Price",
    "Mileage",
  ];
  const mocks = [
    tableMock1,
    tableMock2,
    tableMock3,
    tableMock4,
    tableMock5,
    tableMock6,
    tableMock7,
    tableMock8,
    tableMock9,
    tableMock10
  ];

  useEffect(() => {
    setCurrentTableData(tableMock1);
  }, []);

  const onPageChange = (page) => {
    setCurrentPage(page);
    setCurrentTableData(mocks[page - 1]);
  };

  const PaginationProps = [
    {
      name: "currentPage",
      type: "number",
      defaultProp: "",
    },
    {
      name: "totalCount",
      type: "number",
      defaultProp: "",
    },
    {
      name: "pageSize",
      type: "number",
      defaultProp: "10",
    },
    {
      name: "onPageChange",
      type: "function",
      defaultProp: "",
    },
    {
      name: "siblingCount",
      type: "number",
      defaultProp: "1",
    },
    {
      name: "className",
      type: "string",
      defaultProp: "",
    },
  ];

  return (
    <div className="page-component-spacing">
      <h4>Pagination and Table Components</h4>
      <div className="pagination-table-container">
        <Table
          headers={headers}
          data={currentTableData.items}
          RowComponent={TableRow}
        />
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={currentTableData.totalCount}
          pageSize={PageSize}
          onPageChange={onPageChange}
          siblingCount={2}
        />
      </div>
      <div className="page-component-spacing">
        <PropsDescription componentProps={PaginationProps} />
      </div>
    </div>
  );
};

export default PaginationContainer;
