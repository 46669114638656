import React from 'react';
import PropTypes from 'prop-types';
import { copyrightStatement, acceptTermStatement,
    visitorAgreement, privacyStatement, visitorAgreementUrl, 
    privacyStatementUrl, adChoices, adChoicesUrl, adChoicesIconSrcUrl,
    autotrader, autotraderUrl } from '../resource';

import './Footer.css';

const FooterContainer = ({ menuResponse, footerLinkBaseUrl, sendAnalytics })  => {
    const { navHeaders } = menuResponse

    const findFooterLinkDetails = (navArray, linkName) => navArray.find(nav => nav.navTitle.toLowerCase() === linkName);

    const dashboard = findFooterLinkDetails(navHeaders, 'dashboard');
    const vehicles = findFooterLinkDetails(navHeaders, 'vehicles');
    const prospects = findFooterLinkDetails(navHeaders, 'prospects');
    const advertising = findFooterLinkDetails(navHeaders, 'advertising products');
    const multimedia = findFooterLinkDetails(navHeaders, 'multimedia');
    const reporting = findFooterLinkDetails(navHeaders, 'reporting');

    return(
        <div className="footer-container">
            <div className="footer-content">
                <div>{copyrightStatement}</div>
                <div>
                    <span className="footer-row-margin">{acceptTermStatement}</span>
                    <span><a href={visitorAgreementUrl} target="_blank" rel="noreferrer" onClick={() => {if(sendAnalytics) sendAnalytics(visitorAgreement)}}>{visitorAgreement}</a></span>
                    <span className="footer-links-separator">|</span>
                    <span> <a href={privacyStatementUrl} target="_blank" rel="noreferrer" onClick={() => {if(sendAnalytics) sendAnalytics(privacyStatement)}}>{privacyStatement}</a></span>
                    <span className="footer-links-separator">|</span>
                    <span>
                        <a href={adChoicesUrl} target="_blank" rel="noreferrer" onClick={() => {if(sendAnalytics) sendAnalytics(adChoices)}}>
                            <img id="_bapw-icon" src={adChoicesIconSrcUrl} alt=""/>
                            <span>{adChoices}</span>
                        </a>
                    </span>
                    <span className="footer-links-separator">|</span>
                    <span><a href={autotraderUrl} onClick={() => {if(sendAnalytics) sendAnalytics(autotrader)}}>{autotrader}</a></span>
                </div>
                <div>
                    <span><a href={footerLinkBaseUrl + dashboard.href} onClick={() => {if(sendAnalytics) sendAnalytics(dashboard.navTitle)}}>{dashboard.navTitle}</a></span>
                    <span className="footer-links-separator">|</span>
                    <span><a href={footerLinkBaseUrl + vehicles.href} onClick={() => {if(sendAnalytics) sendAnalytics(vehicles.navTitle)}}>{vehicles.navTitle}</a></span>
                    <span className="footer-links-separator">|</span>
                    <span><a href={footerLinkBaseUrl + prospects.href} onClick={() => {if(sendAnalytics) sendAnalytics(prospects.navTitle)}}>{prospects.navTitle}</a></span>
                    <span className="footer-links-separator">|</span>
                    <span><a href={footerLinkBaseUrl + advertising.href} onClick={() => {if(sendAnalytics) sendAnalytics(advertising.navTitle)}}>{advertising.navTitle}</a></span>
                    <span className="footer-links-separator">|</span>
                    <span><a href={footerLinkBaseUrl + multimedia.href} onClick={() => {if(sendAnalytics) sendAnalytics(multimedia.navTitle)}}>{multimedia.navTitle}</a></span>
                    <span className="footer-links-separator">|</span>
                    <span><a href={footerLinkBaseUrl + reporting.href} onClick={() => {if(sendAnalytics) sendAnalytics(reporting.navTitle)}}>{reporting.navTitle}</a></span>
                </div>
            </div>
        </div>
    );
};

FooterContainer.propTypes = {
    menuResponse: PropTypes.shape({
        navHeaders: PropTypes.arrayOf(PropTypes.shape(
            {
              navTitle:  PropTypes.string.isRequired,
              href: PropTypes.string.isRequired,
              navItems: PropTypes.arrayOf(PropTypes.shape({
                  navItemTitle: PropTypes.string,
                  href: PropTypes.string,
                  visible: PropTypes.bool
              })).isRequired
            }
        )).isRequired,
        userType: PropTypes.string.isRequired
    }).isRequired,
    footerLinkBaseUrl: PropTypes.string.isRequired,
    sendAnalytics: PropTypes.func
};

export default FooterContainer;
