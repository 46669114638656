import React from 'react';
import PropTypes from 'prop-types';
import UniversalLink from '../UniversalLink/UniversalLink';

import './SideNavigationBar.css'

const SideNavigationBar = ({ sideNavResponse, sideNavBaseUrl, currentPage, sendAnalytics }) => {

    const showTitle = (navLinks) => {
        let show = false;

        for(let link of navLinks) {
            if(link.visible) {
                show = true;
                break;  
            }
        }

        return show;
    };

    const LinkItem = (link, index) => {
        if(!link.visible) return;
    
        return (
            <div key={index} className={`side-nav-bar-link-wrapper ${link.href.includes(currentPage) ? 'side-nav-links-active' : ''}`}>
                <UniversalLink href={`${link.microsite ? "" : sideNavBaseUrl }${link.href}`} className="inherited-link" onClick={() => {if(sendAnalytics) sendAnalytics(link.linkTitle)}}>
                    {link.linkTitle}
                </UniversalLink>
            </div> 
        )
      }

    return (
        <div className="side-nav-bar-container">
            <div className="side-nav-bar-wrapper">
                <div className="side-nav-bar-content">
                    { 
                        sideNavResponse.map((sideNav, index1) => {
                            return (
                                showTitle(sideNav.sideNavigationLinks) &&
                                <div key={index1} className="side-nav-section">
                                    <div className="side-nav-title">{sideNav.sideNavigationTitle}</div>
                                    <hr className="side-nav-title-hr" />
                                    {
                                        sideNav.sideNavigationLinks.map((link, index2) => {
                                            return LinkItem(link, index2)
                                        })
                                    }
                                    
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            
        </div>
    );
};

SideNavigationBar.propTypes = {
    sideNavResponse: PropTypes.arrayOf(PropTypes.shape({
        sideNavigationTitle: PropTypes.string.isRequired,
        sideNavigationLinks: PropTypes.arrayOf(PropTypes.shape({
            linkTitle: PropTypes.string.isRequired,
            href: PropTypes.string.isRequired,
            visible: PropTypes.bool.isRequired
        }))
    })).isRequired,
    sideNavBaseUrl: PropTypes.string.isRequired,
    currentPage: PropTypes.string,
    sendAnalytics: PropTypes.func
  };
  

export default SideNavigationBar;
