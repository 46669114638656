import React, { useState } from 'react';
import Modal from '../../reusableComponents/Modal/Modal';
import PropsDescription from '../../components/PropsDescription/PropsDescription';
import './ModalContainer.css';
import { Button } from '../../reusableComponents';

const ModalContainer = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const ModalProps = [
    {
      name: 'open',
      type: 'boolean',
      defaultProp: false,
      notes: 'State of modal: open/close',
    },
    {
      name: 'onClose',
      type: 'function',
      defaultProp: '',
      notes: 'Action to close modal',
    },
    {
      name: 'title',
      type: 'any',
      defaultProp: '',
      notes: 'Title of the modal',
    },
    {
      name: 'closeIcon',
      type: 'boolean',
      defaultProp: 'true',
      notes: 'Show close icon',
    },
    {
      name: 'children',
      type: 'any',
      defaultProp: '',
      notes: 'Body of the modal',
    },
    {
      name: 'style',
      type: 'object: { modalContainer: string, title: string, body: string, closeIcon: string }',
      notes: 'Apply additional styling to modal',
    },
  ];

  const title = <p className="modal-title">Example Modal</p>;
  const body = (
    <>
      <p className="modal-body">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sollicitudin sapien ut pharetra mattis.
        Pellentesque interdum cursus eros, ac sollicitudin orci pretium a. Praesent pharetra magna eu tempus porta. Nam
        sit amet tristique libero, sit amet aliquet velit. Curabitur non fermentum ex, vel cursus nulla. Pellentesque ac
        magna tempus, fringilla velit id, imperdiet dolor.
      </p>
      <div className="modal-button-container">
        <Button
          primary
          class="modal-button"
          onClick={closeModal}
        >
          Close Modal
        </Button>
      </div>
    </>
  );

  return (
    <div className="page-component-spacing ">
      <h4>Modal Component</h4>
      <Button onClick={openModal}>Open Modal</Button>
      <div className="page-component-spacing">
        <PropsDescription componentProps={ModalProps} />
      </div>
      <Modal
        open={showModal}
        onClose={closeModal}
        title={title}
      >
        {body}
      </Modal>
    </div>
  );
};

export default ModalContainer;
