import React, { useState, useRef, useEffect } from 'react';
import './EditPopup.css';
import Input from '../Input/Input.js';
import Button from '../Button/Button';
import { default as CancelIcon } from '../../icons/Cancel';
import { default as CheckCircleIcon } from '../../icons/CheckCircle';

const EditPopup = ({ children, dataId, label, value, required, prefix, onInput, onSave, error }) => {
  const [visible, setVisible] = useState(false);
  const [style, setStyle] = useState({});
  const elRef = useRef(null);
  const [inputValue, setInputValue] = useState(value);

  const showTooltip = () => {
    const dimensions = elRef.current.getBoundingClientRect();

    style.left = dimensions.left + dimensions.width / 2;

    if (dimensions.top < window.innerHeight / 2) {
      style.top = dimensions.top + dimensions.height;
    } else {
      style.bottom = window.innerHeight - dimensions.top;
    }

    setStyle(style);
    setVisible(true);
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
    onInput(e);
  };

  const handleSave = () => {
    setStyle({});
    setVisible(false);

    onSave(dataId, inputValue);
  };

  const handleCancel = () => {
    setStyle({});
    setVisible(false);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <span
      onClick={() => {
        if (!visible) showTooltip();
      }}
      className="edit-popup-text"
      data-testid="edit-popup"
      ref={elRef}
    >
      {children}

      {visible && (
        <>
          <div
            className="edit-popup-overlay"
            onClick={handleCancel}
          ></div>
          <div
            className={`edit-popup-body`}
            style={style}
          >
            {label && (
              <div className="label">
                {label}
                {inputValue && required && <span className="required">*</span>}
              </div>
            )}
            <Input
              variant
              value={inputValue}
              type="text"
              prefix={prefix}
              onInput={handleChange}
              error={error}
            />
            <Button
              id="edit-popup-save-button"
              text
              onClick={handleSave}
              disabled={inputValue === value ? true : undefined}
            >
              <CheckCircleIcon />
            </Button>
            <Button
              id="edit-popup-cancel-button"
              text
              onClick={handleCancel}
              className="close-button"
            >
              <CancelIcon />
            </Button>
          </div>
        </>
      )}
    </span>
  );
};

export default EditPopup;
