import React, { useState } from 'react';
import styles from './Accordion.module.scss';
import ChevronDown from '../../icons/ChevronDown';

export default function Accordion({ header, text, style }) {
  const [showText, setShowText] = useState(false);
  return (
    <div className={styles.container}>
      <div
        className={styles.headerBar}
        role="button"
        onClick={() => setShowText(!showText)}
      >
        <div className={`${styles.header} ${style?.header || ''}`}>{header}</div>
        <ChevronDown className={`${styles.icon} ${showText && styles.rotate} ${style?.icon || ''}`} />
      </div>
      <div className={`${styles.text} ${!showText && styles.hidden} ${style?.text || ''}`}>{text}</div>
    </div>
  );
}
