import React, { useState } from 'react';
import PropsDescription from '../../components/PropsDescription/PropsDescription';
import { ToggleSwitch } from '../../reusableComponents';

const ToggleSwitchContainer  =  () => {
    const [isChecked, setIsChecked] = useState(true);

    const ToggleSwitchProps = [
        {
            name: 'checked',
            type: 'boolean',
            defaultProp: 'false'
        },
        {
            name: 'onChange',
            type: 'function',
            defaultProp: ''
        },
    ];

    const onChange = () => {
        setIsChecked(!isChecked);
    }



    return (
        <div className="page-component-spacing ">
            <h4>Toggle Switch Component</h4>
            <ToggleSwitch checked={isChecked} onChange={onChange}>Include</ToggleSwitch>
            <div className="page-component-spacing">
                <PropsDescription componentProps={ToggleSwitchProps}/>
            </div>
        </div>
    );
}

export default ToggleSwitchContainer;