import React, { useState, useEffect } from 'react';
import { DndContext, closestCenter, MouseSensor, TouchSensor, DragOverlay, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';

import { Grid } from './Grid';
import { SortableImage } from './SortableImage';
import { PlainImage } from './PlainImage';
import Modal from '../Modal/Modal';
import PropTypes from 'prop-types';
import Spinner from '../Spinner/Spinner';
import Alerts from '../Alerts/Alerts';
import './SortableImageGrid.css';

const SortableImageGrid = ({
  images,
  onReorder,
  showReorderSave,
  onReorderSave,
  onDelete,
  getLargeUrl,
  columns = 5,
  sendAnalytics,
}) => {
  const [items, setItems] = useState(images);
  const [activeId, setActiveId] = useState(null);
  const [showImgModal, setShowImgModal] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [imgToBeDeleted, setImgToBeDeleted] = useState(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  useEffect(() => {
    setItems(images);
  }, [images]);

  /* On Click Functions */

  const expandImage = (image) => {
    const largeUrl = getLargeUrl(image);
    setShowImgModal(true);

    const img = new Image();
    img.onload = () => {
      setModalImageUrl(largeUrl);
    };
    img.src = largeUrl;
  };

  const onCancelImageModal = () => {
    if (sendAnalytics?.sendCancelSortableImageModalEvent) {
      sendAnalytics.sendCancelSortableImageModalEvent();
    }
    setShowImgModal(false);
    setModalImageUrl('');
  };

  const onSaveClick = async (e) => {
    e.currentTarget.disabled = true;
    await onReorderSave();
  };

  const onDeleteIconClick = (image) => {
    if (sendAnalytics?.sendOpenSortableDeleteModalEvent) {
      sendAnalytics?.sendOpenSortableDeleteModalEvent();
    }
    setShowDeleteModal(true);
    setImgToBeDeleted(image);
  };

  const onDeleteClick = async (e) => {
    e.currentTarget.disabled = true;
    await onDelete(imgToBeDeleted);
    setShowDeleteModal(false);
  };

  const onCancelDeleteModal = () => {
    if (sendAnalytics?.sendCancelSortableDeleteModalEvent) {
      sendAnalytics.sendCancelSortableDeleteModalEvent();
    }
    setShowDeleteModal(false);
  };

  /* Handle Image Drag and Drop Functions */

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        onReorder(arrayMove(items, oldIndex, newIndex));

        return arrayMove(items, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  };

  const handleDragCancel = () => {
    setActiveId(null);
  };

  return (
    <div
      data-testid="sortable-image-grid"
      className="sortable-image-grid"
    >
      {showReorderSave && (
        <div className="sortable-save-container">
          <Alerts
            type="message"
            dismissible={false}
          >
            <div className="sortable-save-alert-container">
              <div className="sortable-save-msg">
                The order of the photos has changed. When finished reordering, please <b>Save</b> the changes.
              </div>
              <button
                className="sortable-btn sortable-btn-primary sortable-save-button"
                data-testid="sortable-save-button"
                onClick={onSaveClick}
              >
                Save Photo Order
              </button>
            </div>
          </Alerts>
        </div>
      )}
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <SortableContext
          items={items}
          strategy={rectSortingStrategy}
        >
          <Grid columns={columns}>
            {items.map((item, index) => (
              <SortableImage
                key={index}
                image={item}
                id={item.id}
                url={item.imageUrl}
                expandImage={expandImage}
                onDelete={onDeleteIconClick}
              />
            ))}
          </Grid>
        </SortableContext>

        <DragOverlay adjustScale={true}>
          {activeId ? (
            <PlainImage
              url={items.find((item) => item.id === activeId).imageUrl}
              index={items.findIndex((item) => item.id === activeId)}
            />
          ) : null}
        </DragOverlay>
        <Modal
          open={showImgModal}
          onClose={onCancelImageModal}
        >
          <img
            className={`${modalImageUrl ? '' : 'no-display'}`}
            src={modalImageUrl}
            alt="full vehicle"
          />
          <div className={`vehicle-image-spinner ${modalImageUrl ? 'no-display' : ''}`}>
            <Spinner
              height="80px"
              width="80px"
              radius="30"
            />
          </div>
        </Modal>
        <Modal
          open={showDeleteModal}
          onClose={onCancelDeleteModal}
          closeIcon={false}
        >
          <div
            className="sortable-delete-image-modal"
            data-testid="sortable-delete-image-modal"
          >
            <button
              className="sortable-delete-x-button"
              data-testid="sortable-delete-x-button"
              onClick={onCancelDeleteModal}
            >
              <i className={`icon icon-16 icon-e-remove-1`} />
            </button>
            <h4>Delete Photo</h4>
            <p>Please confirm that you want to delete this photo from your uploads.</p>
            <div className="sortable-delete-button-list">
              <button
                className="sortable-btn sortable-btn-secondary sortable-delete-cancel-button"
                data-testid="sortable-delete-cancel-button"
                onClick={onCancelDeleteModal}
              >
                Cancel
              </button>
              <button
                className="sortable-btn sortable-btn-primary sortable-delete-button"
                data-testid="sortable-delete-button"
                onClick={onDeleteClick}
              >
                Delete Photo
              </button>
            </div>
          </div>
        </Modal>
      </DndContext>
    </div>
  );
};

SortableImageGrid.propTypes = {
  onReorderSave: PropTypes.func,
  showReorderSave: PropTypes.bool,
  onReorder: PropTypes.func,
  onDelete: PropTypes.func,
  getLargeUrl: PropTypes.func,
  images: PropTypes.array,
  columns: PropTypes.number,
  sendAnalytics: PropTypes.object,
};

export default SortableImageGrid;
